.in-trusted-item {
    margin: 100px auto !important;
}

.in-automate-item {
    margin: 50px auto !important;
}

.in-new-aisimplified-section-main-item {
    margin: 0px 0px 200px !important;
}


@media only screen and (min-width: 0px) and (max-width: 900px) {
    .in-trusted-item {
        margin: 20px auto !important;
    }
}