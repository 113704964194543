.gbs-cards-container {
    display: flex !important;
}

.gbs-card-item {
    /* width: 280px !important;
    min-width: 280px !important; */
    max-width: 380px !important;
    /* height: 400px !important; */
    min-height: 380px !important;
    max-height: fit-content !important;
    background-color: #FAFAFA;
    border-radius: 10px !important;
    /* border-bottom: 3px solid; */
    /* border-image-source: linear-gradient(92deg, #6A097D 4.11%, #3C8CEC 100%) !important; */
    /* border-image-slice: 1; */
    overflow: hidden !important;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); */
    transition: box-shadow 0.3s ease;
    position: relative !important;
}

.gbs-card-item::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px !important; 
    padding: 0px 0px 4px 0px; 
    background: linear-gradient(92deg, #6A097D 4.11%, #3C8CEC 100%) !important;
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
}

.gbs-card-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 20px rgba(0, 0, 0, 0.15);
    /* Slightly stronger shadow on hover */
}

.gbs-card-head {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.gbs-card-subhead {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.gbs-card-image {
    width: 100%;
    /* height: 150px;
    max-height: 150px;
    min-height: 150px; */
    object-fit: cover;
    /* To ensure images fill without distortion */
    border-top-left-radius: 10px;
    /* Optional: Match border radius */
    border-top-right-radius: 10px;
    /* Optional: Match border radius */
}

.gbs-professional-solution-text {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    line-height: 30px !important;
}

@media screen and (max-width: 1200px) {
    .gbs-cards-container {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width: 900px) {
    .gbs-professional-solution-text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 600px) {
    .gbs-professional-solution-text {
        font-size: 23px !important;
    }

    .gbs-cards-container {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .gbs-card-item {
        max-width: 320px !important;
        min-width: 280px !important;
    }
}