.chatbot-gradient-background {
    background-image: url("../../assests/Chatbot-Background.svg");
    background-repeat: no-repeat;
    height: 350px;
    margin: 50px 0px !important;
    border-radius: 20px;
    margin-inline: auto !important;
}

.chatbot-add-to-browser-button {
    background: #fbf4ff !important;
    border-radius: 10px !important;
    color: #682a7a !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    padding: 10px 18px !important;
    text-transform: none !important;
    cursor: pointer !important;
}

.chatbot-gradient-text {
    color: white !important;
    font-size: 30px !important;
    padding-bottom: 30px !important;
    text-align: center !important;
}

.cb-gradiant-main-item {
    margin: 50px auto 80px !important;
    padding: 60px !important;
    background-image: url("../../assests/Chatbot-Background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px !important;
}

.chatbot-demno-btn {
    background-color: #fbf4ff !important;
    color: #682a7a !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    width: 180px !important;
}

.chatbot-try-btn {
    background-color: transparent;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    border: 1px solid white !important;
    box-shadow: none !important;
    width: 180px !important;
}

.cb-gradiant-head-text {
    font-size: 42px !important;
    font-weight: 500 !important;
    color: #fff !important;
}

.chatbot-grd-btn-container {
    gap: 40px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 800px) {
    .chatbot-gradient-background {
        height: 300px !important;
    }

    .chatbot-add-to-browser-button {
        font-size: 18px !important;
        padding: 10px 15px !important;
    }

    .chatbot-gradient-text {
        font-size: 25px !important;
    }
}

@media (max-width: 550px) {
    .chatbot-add-to-browser-button {
        font-size: 15px !important;
        padding: 8px 10px !important;
    }

    .chatbot-gradient-text {
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
    .cb-gradiant-main-item {
        padding: 60px 30px !important;
    }

    .cb-gradiant-head-text {
        font-size: 30px !important;
        font-weight: 500 !important;
        color: #fff !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cb-gradiant-container {
        margin: 20px auto;
    }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
    .cb-gradiant-main-item {
        padding: 60px 10px !important;
        margin: 10px auto !important;
    }

    .cb-gradiant-head-text {
        font-size: 20px !important;
        font-weight: 500 !important;
        color: #fff !important;
    }

    .chatbot-demno-btn,
    .chatbot-try-btn {
        font-size: 12px !important;
        width: 120px !important;
        padding: 5px !important;
    }

    .chatbot-grd-btn-container {
        gap: 20px;
    }
}