.omni-side-by-side-hero-section {
    padding: 90px auto 0 !important;
    width: 100%;
    min-height: 700px;
    height: 100vh;
    align-items: center;
    background-color: #F2EEE4;
}

.omni-side-by-side-section {
    /* padding: 0 auto 20px !important; */
    width: 100%;
    min-height: 700px;
    background-color: #E2F0E8;
}

.omni-cardComponent {
    margin: 0 auto 30px !important;
}

.omni-cta-banner {
    margin: 50px auto 50px !important;
    background-color: #6A097D;
    padding: 60px 40px !important;
}

.omni-carousel-animation-section {
    width: 100%;
    margin: 100px auto !important;
}

.omni-faq-section {
    margin: 100px auto 150px !important;
}

.omni-footer {
    margin: 80px auto 0 !important;
}

.omni-hero-section-btn-style {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background-color: #F2C970 !important;
    color: #000000 !important;
    border-radius: 25px !important;
    padding: 10px !important;
    width: auto;
}

.omni-section-btn-style {
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background-color: #F2C970 !important;
    color: #000000 !important;
    padding: 10px !important;
    width: auto;
    border-radius: 10px !important;
}

.omini-gbl-contained-btn-style {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
    background-color: #6A097D !important;
    text-transform: none !important;
    border-radius: 5px !important;
}