.CBS-mainOuterContainer {
  background: #6a097d;
  min-height: 100px;
  width: 100%;
  justify-content: center;
}

.CBS-mainInfoContainer {
  justify-content: space-between;
  margin-block: 130px;
  gap: 50px;
}

.CBS-MainInfoTextContainer {
  /* height: 100%; */
  justify-content: space-between;
}

.CBS-InfoTextMainText {
  font-size: 48px;
  color: #fff;
  font-weight: 500;
  line-height: 60.81px;
}

.CBS-InfoTextMainText span {
  color: #f2c970;
}

.CBS-InfoText-IconContainer {
  justify-content: flex-start;
  column-gap: 30px;
}

.CBS-icon-main-item {
  align-self: flex-end;
}

/* @media only screen and (min-width: 650px) and (max-width: 1200px) {
  .CBS-InfoText-IconContainer {
    justify-content: space-between;
  }
  .CBS-icon-main-item {
    margin-top: 40px;
  } */
/* } */
.CBS-InfoText-Icon {
  text-align: center;
  width: 40px;
  height: 40px;
}

.CBS-InfoText-Icon a svg {
  color: #fff;
  vertical-align: sub;
}

.CBS-stateCardContainer {
  justify-content: space-between;
}

.SC-Main-OuterMainContainer {
  /* width: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  background: linear-gradient(180deg, #ffffff 0%, #f7e9ff 100%);
  border-radius: 20px;
  height: 100%;
}

.SC-numberValue {
  font-size: 52px;
  font-weight: 600;
  color: #1f384c;
}

.SC-textValue {
  font-size: 20px;
  color: #1f384c;
  text-align: center;
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .CBS-InfoTextMainText {
    font-size: 42px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .CBS-InfoTextMainText {
    font-size: 36px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .CBS-mainInfoContainer {
    margin: 50px auto;
    gap: 25px;
  }

  .CBS-InfoTextMainText {
    /* font-size: 40px; */
    line-height: normal;
  }

  .CBS-icon-main-item {
    margin-top: 35px;
  }

  .CBS-icon-main-item {
    margin-top: 35px;
  }

  .cbs-work-flow-icons {
    width: 35px;
  }

  .cbs-work-flow-fb-icons {
    height: 35px;
  }

  .SC-Main-OuterMainContainer {
    padding: 15px;
  }

  .SC-numberValue {
    font-size: 35px;
  }

  .SC-textValue {
    font-size: 18px;
    margin-top: 5px;
    text-align: center;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CBS-mainInfoContainer {
    margin: 35px auto;
    gap: 25px;
  }

  .CBS-InfoTextMainText {
    font-size: 28px;
    line-height: normal;
  }

  .CBS-icon-main-item {
    margin-top: 35px;
  }

  .cbs-work-flow-icons {
    width: 30px;
  }

  .cbs-work-flow-fb-icons {
    height: 30px;
  }

  .SC-Main-OuterMainContainer {
    padding: 15px;
  }

  .SC-numberValue {
    font-size: 25px;
  }

  .SC-textValue {
    font-size: 13px;
    margin-top: 5px;
    text-align: center;
  }
}