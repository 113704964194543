/* Default Styles for large screens (above 1200px) */
.website-side-by-side-hero-section {
    padding: 90px auto 0 !important;
    width: 100%;
    min-height: 700px;
} 

.website-side-by-side-footer-section {
    padding: 90px auto 0 !important;
    width: 100%;
}

.website-heroSection-label-style {
    color: #6A097D !important;
    font-weight: 600 !important;
}

.website-hero-section-heading-style {
    font-size: 40px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    line-height: 1.25 !important;
}

.website-hero-section-description-style {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    line-height: 1.25 !important;
}

/* Media Queries for medium screens (between 900px and 1200px) */
@media only screen and (max-width: 1200px) {
    .website-hero-section-heading-style {
        font-size: 36px !important;
        font-weight: 600 !important;
    }

    .website-hero-section-description-style {
        font-size: 18px !important;
        font-weight: 500 !important;
    }
}

/* Media Queries for small screens (between 600px and 900px) */
@media only screen and (max-width: 900px) {
    .website-hero-section-heading-style {
        font-size: 32px !important;
        font-weight: 600 !important;
    }

    .website-hero-section-description-style {
        font-size: 16px !important;
        font-weight: 500 !important;
    }
}

/* Media Queries for extra small screens (below 600px) */
@media only screen and (max-width: 600px) {
    .website-hero-section-heading-style {
        font-size: 28px !important;
        font-weight: 600 !important;
    }

    .website-hero-section-description-style {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .website-side-by-side-hero-section {
        padding: 60px auto 0 !important;
    } 
    .website-side-by-side-footer-section {
        padding: 60px auto 0 !important;
    }
}