/* styles.css */
.navbar {
    background: #333;
}

.dropdownMenu {
    position: absolute;
    top: 150px;
    left: 0;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 200px;
    /* Width can be adjusted based on content */
}

.dropdownButton {
    display: block;
    width: 100%;
    color: black;
    padding: 12px 16px;
    text-align: left;
}

.dropdownButton:hover {
    background-color: #f1f1f1;
}


.navbar-nested-menu {
    position: fixed !important;
    inset: 100% 0% auto !important;
    background-color: white !important;
    border-radius: 5px !important;
    /* left: 25% !important; */
    width: 850px !important;
    min-width: 800px !important;
    max-width: 850px !important;
    max-height: calc(100vh - 200px) !important;
    overflow: auto !important;
}

.nav-menu-img-item {
    max-width: 50px !important;
    width: 50px !important;
    min-width: 50px !important;
    height: 44px !important;
    max-height: 44px !important;
    min-height: 44px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-menu-text-item {
    width: calc(100% - 60px) !important;
    max-width: calc(100% - 60px) !important;
    position: relative;
}

.nav-menu-text {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.nav-menu-desc {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.nav-menu-main-container {
    justify-content: space-between;
}

.nav-menu-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #7A7A7A !important;
}

.nav-menu-hovered-arrow {
    position: absolute;
    right: 2px;
    top: 0px;
}