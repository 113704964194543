.comparison-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 20px;
    gap: 45px;
    position: relative !important;
}

.comparison-parts {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.comparison-column {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 20px 30px;
    border-radius: 12px;
    text-align: left;
    /* height: 400px; */
}

.vs-style-container {
    position: absolute !important;
    transform: translate(-1.5%, 50%) !important;
    width: 100% !important;
    height: 100% !important;
}

.vs-style-text {
    background-color: black !important;
    color: #FFFFFF !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    border-radius: 50% !important;
    padding: 20px 25px !important;
}

.part-headingName {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.features-include {
    font-size: 20px;
    font-weight: 600;
}

.tick-lists {
    list-style: none;
    text-align: left;

    margin-left: 5px !important;
}

.comparison-column ul {
    padding-left: 5px !important;
}


.tick-lists,
.cross-lists {
    margin-top: 15px !important;
    margin-left: 5px !important;
}

.tick-lists li,
.cross-lists li {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 0;

}

.tick-lists li::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
}

.cross-lists li::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
}

.tick-lists li::before {
    background-image: url('./../../assests/LandingPage/Tick.svg');
    /* Custom tick icon */
}

.cross-lists li::before {
    background-image: url('./../../assests/LandingPage/Cross.svg');
}

@media screen and (max-width: 900px) {
    .comparison-container {
        flex-direction: column !important;
    }

    .vs-style-container {
        position: relative !important;
        transform: none !important;
        width: 100% !important;
        height: 100% !important;
    }

    .vs-style-text {
        font-size: 24px !important;
    }

    .part-headingName {
        font-size: 23px;
    }

    .features-include {
        font-size: 20px;
        font-weight: 600;
    }

    ul {
        font-size: 15px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .comparison-container {
        padding: 0px 0px;
    }

    .comparison-column {
        padding: 30px 15px !important;
    }
}