@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&family=Roboto:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Open Sans', sans-serif !important;

}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
} */

::-webkit-scrollbar {
  /* width: 10px; */
  /* display: none; */
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  /* background: #7d288d; */
  background: #444444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #7d288d; */
  background: #444444;
}

button:disabled {
  opacity: 0.5;
}

p, h2, h3, h5, h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}
/* ::selection {
  background-color: #F8ECFF;
} */

/* ::-moz-selection {
  background-color: #F8ECFF;
} */