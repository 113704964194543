.cw-mark-img {
    vertical-align: middle;
}

.cw-grid-dt-text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px !important;
}

.cw-grid-dd-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px !important;
}

.cw-point-head-text {
    margin-bottom: 30PX !important;
    /* font-size: 33px !important;
    font-weight: 500 !important; */
}

.cw-feature-sub-container {
    justify-content: space-between;
    align-items: center;
    margin: 80px auto !important;
}

.cw-features-points-outter-container {
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    margin-top: 15px;
}

.cw-card-frame-container {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    gap: 50px;
    height: 100% !important;
}

@media screen and (max-width : 600px) and (min-width : 0px) {

    .wpc-grid-infp-img {
        width: 100%;
    }

    .cw-feature-sub-container {
        justify-content: space-between;
        align-items: center;
        margin: 40px auto 40px !important;
    }

    .cw-grid-dd-text {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 25px !important;
    }
}