.gitex-timer-container {}

.gitex-timer-heading {
    font-weight: 600;
    color: #6A097D;
    font-size: 55px;
}

.gitex-main-head-item {
    margin: 0 auto !important;
}

.gitex-timer-subheading {
    background: linear-gradient(121.52deg, #6A097D 10.73%, rgba(10, 106, 221, 0.982854) 46.75%, rgba(255, 183, 98, 0.972567) 91.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 5px;
}

.gitex-timer-date {
    background: linear-gradient(121.52deg, #6A097D 10.73%, rgba(10, 106, 221, 0.982854) 46.75%, rgba(255, 183, 98, 0.972567) 91.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 22px;
}

.gitex-timer-place {
    color: #404040;
    font-weight: 500;
    font-size: 20px;
}

.gitex-timer-button {
    background-color: #6A097D !important;
    border: none !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.timer-number {
    font-size: 40px;
    display: block;
    text-align: center;
    font-weight: 600;
    color: #404040;
}

.timer-label {
    font-size: 12px;
    text-align: center;
    display: block;
    color: #404040;
    font-weight: 500;
}

.timer-colon {
    font-weight: 600;
    font-size: 40px;
    display: flex;
    align-self: baseline;
}

.gitex-bg-image-item {
    position: absolute;
    top: 0;
    right: 0;
}

.gitex-dubai-image {
    position: absolute;
    top: 0;
    right: 0;

}

.gitex-dubai-image-div {
    position: relative;
}


.gitex-timer-head-item {
    margin: 0px 0px 20px !important;
}

.gitex-subhead-text {
    font-size: 21px !important;
    font-weight: 500 !important;
    color: #404040 !important;
}

.gitex-subhead-text-item {
    margin: 0px 0px 15px !important;
}

.gitex-timer-main-item {
    margin: 15px auto !important;
}

.gitex-book-booth-btn-item {
    margin: 15px 0px !important;
}

.gitex-book-booth-head {
    font-size: 17px !important;
    font-weight: 500 !important;
    color: #404040 !important;
}

.gitex-image-img {
    width: 100%;
    height: 100%;
}