.common-section-white-container {
    /* background: linear-gradient(to bottom, rgba(252, 243, 233, 1), rgba(252, 243, 233, 0.6)); */
    background-size: 100% 100% !important;
    background-position: center !important;
    color: white !important;
    /* height: 62vh !important; */
}

.common-section-dark-container {
    /* background-color: #190D39 !important; */
    /* background: linear-gradient(to right, rgba(17,7,46,1), rgba(17,7,46,1), rgba(42,9,125,1)); */
    /* background: linear-gradient(to bottom, rgba(229, 220, 239, 1), rgba(229, 220, 239, 0.5)); */
    background-size: 100% 100% !important;
    background-position: center !important;
    color: white !important;
    /* height: 65vh !important; */
}

.common-section-white-item {
    margin: 70px auto 100px !important;
}

.common-section-dark-item {
    margin: 70px auto 150px !important;
}

.commnon-subfield-text-style {
    font-size: 20px !important;
    font-weight: 700 !important;
    /* color: #6A097D !important; */
}

.common-format-heading-style {
    font-size: 36px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.format-heading-item-style {
    width: 100% !important;
    max-width: 90% !important;
}

.common-format-details-style {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.common-format-button-styles {
    text-transform: capitalize !important;
    border-radius: 5px !important;
    /* background-color: #FFB762 !important; */
    /* border: 1px solid #124CEA !important; */
    font-size: 19px !important;
    font-weight: 500 !important;
    color: #FFFFFF !important;
    padding: 10px 20px !important;
    white-space: nowrap !important;
}

/* .common-format-btn-white-styles {
    background-color: rgba(148, 148, 148, 0.2) !important;
    color: black !important;
    padding: 32px 48px !important;
    max-width: 80% !important;
    border-radius: 10px !important;
} */

.common-section-button-components-item {
    position: absolute !important;
    bottom: 0 !important;
    transform: translateY(50%) !important;
    padding-right: 30px !important;
}

.common-format-btn-dark-styles {
    background-color: #061A44 !important;
    color: white !important;
    padding: 38px 50px !important;
    max-width: 540px !important;
    border-radius: 10px !important;
}

.common-format-btn-percentage {
    font-size: 54px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    /* color: white !important; */
}

.common-format-btn-data {
    font-size: 20px !important;
    font-weight: 500 !important;
    /* color: white !important; */
}

.common-section-left-image-container {
    background-color: #FFFFFF !important;
    border-radius: 10px !important;
    position: absolute !important;
    /* transform: translateX(-5%) !important; */
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.15) !important; */
    left: -10% !important;
    /* padding: 50px 40px !important; */
}

.common-section-right-image-container {
    background-color: #FFFFFF !important;
    border-radius: 10px !important;
    /* transform: translateX(100%) !important; */
    position: absolute !important;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.15) !important; */
    right: -10% !important;
    /* padding: 20px 30px !important; */
}

.common-section-image {
    max-width: 30vw !important;
    /* max-height: 700px !important; */
    height: 632x !important;
    max-height: 632px !important;
    /* float: right !important; */
}

@media screen and (max-width: 1650px) {
    .common-format-heading-style {
        font-size: 30px !important;
    }

    .common-format-details-style {
        font-size: 17px !important;
    }

    .common-format-btn-percentage {
        font-size: 50px !important;
    }

    .common-format-btn-data {
        font-size: 16px !important;
    }

    .common-format-button-styles {
        padding: 8px 18px !important;
        font-size: 15px !important;
    }

    .common-format-btn-dark-styles {
        padding: 20px 35px !important;
        max-width: 440px !important;
    }

    .format-heading-item-style {
        max-width: 100% !important;
    }
}


@media screen and (max-width: 1535px) {
    .common-section-left-image-container {
        left: -13% !important;
    }

    .common-section-right-image-container {
        right: -13% !important;
    }
}

@media screen and (max-width: 1200px) {
    .common-section-dark-container {
        height: 100% !important;
    }

    .common-section-left-image-container {
        position: relative;
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .common-format-btn-dark-styles {
        padding: 15px 28px !important;
        /* max-width: 100% !important; */
    }

    .common-section-left-image-container {
        position: relative;
    }

    .common-format-btn-dark-styles {
        position: absolute !important;
        bottom: 0 !important;
        transform: translateY(50%) !important;
        padding: 18px 18px !important;
        max-width: 100% !important;
    }

    .common-section-white-container {
        height: 100% !important;
    }

    .common-section-dark-container {
        height: 100% !important;
    }

    .common-section-white-item {
        margin: 25px 25px 0px !important;
    }

    .common-section-dark-item {
        margin: 25px 25px 0px !important;
    }

    /* .main-section-common-page {
        margin: 0 !important;
    } */

    .common-section-button-components-item {
        position: relative !important;
        transform: none !important;
        padding: 0 !important;
    }

    .format-heading-item-style {
        max-width: 100% !important;
    }

    .common-format-heading-style {
        font-size: 34px !important;
    }

    .common-format-details-style {
        font-size: 16px !important;
    }

    .common-section-left-image-container {
        position: relative !important;
        /* box-shadow: 0px 0px 10px rgba(0,0,0,0.15) !important; */
        left: 0 !important;
    }

    .common-section-right-image-container {
        position: relative !important;
        /* box-shadow: 0px 0px 10px rgba(0,0,0,0.15) !important; */
        right: 0 !important;
    }

    .common-section-left-image {
        max-width: 118% !important;
        height: 632x !important;
        max-height: 632px !important;
        margin-bottom: 80px !important;
        border-radius: 10px !important;
        transform: translateX(-15%) !important;
        overflow: hidden !important;
    }

    .common-section-right-image {
        max-width: 120% !important;
        height: 632x !important;
        max-height: 632px !important;
        margin-bottom: 80px !important;
        border-radius: 10px !important;
        /* transform: scaleX(1.15) scaleY(1.1) translateX(23px) !important; */
    }
}

@media screen and (min-width: 1300px) and (max-width: 1650px) {
    .common-section-image {
        max-width: none !important;
        /* max-height: 700px !important; */
        height: 550px !important;
        max-height: 550px !important;
        /* float: right !important; */
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .common-format-heading-style {
        font-size: 23px !important;
    }

    .common-format-details-style {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #000000 !important;
    }

    .common-format-button-styles {
        padding: 8px 18px !important;
        font-size: 15px !important;
    }

    .common-format-btn-percentage {
        font-size: 34px !important;
    }

    .common-format-btn-data {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .common-format-heading-style {
        font-size: 25px !important;
    }

    .common-format-details-style {
        font-size: 16px !important;
        font-weight: 500 !important;
        color: #000000 !important;
    }

    .common-format-button-styles {
        padding: 8px 18px !important;
        font-size: 15px !important;
    }

    .common-format-btn-percentage {
        font-size: 36px !important;
    }

    .common-format-btn-data {
        font-size: 17px !important;
    }
}