.automate-span {
    margin: 0px auto 0px !important;
    text-align: center !important;
}

.automate-span span {
    font-size: 40px !important;
    font-weight: 600 !important;
    color: black !important;
}

.in-gcs-style {
    background-color: white !important;
    box-shadow: none !important;
    color: rgba(106, 9, 125, 1) !important;
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    padding: 5px 0px !important;
}

.in-gcs-style:hover {
    text-decoration: underline !important;
    padding: 5px 0px !important;
}

@media only screen and (min-width: 0px) and (max-width: 1030px) {
    .in-gcs-style {
        font-size: 15px !important;
        font-weight: 600 !important;
    }
}



@media only screen and (min-width: 0px) and (max-width: 1200px) {

    .automate-span span {
        font-size: 30px !important;
        font-weight: 600 !important;
        color: black !important;
    }

    .in-automate-item {
        margin: 30px auto !important;
    }
}