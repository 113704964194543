.channel-cards-main-item-section {
    background-color: azure !important;
    padding: 100px 0 !important;
}

.healthcare-heading-text-color {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    line-height: 30px !important;
}

.healthcare-sub-heading-text-color {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    color: #1F384C !important;
}

.card-heading-text-color {
    font-size: 24px !important;
    font-weight: 700 !important;
}

.card-subheading-text-color {
    font-size: 15px !important;
    font-weight: 500 !important;
    height: 50px !important;
}

.card-grid-style-component {
    padding: 20px 10px !important;
}

.card-component-style {
    /* background-color: rgb(226, 255, 254) !important; */
}

@keyframes cardHoverAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1) !important;
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.15) !important;
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1) !important;
    }
}

.card-component-style {
    border-radius: 15px !important;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(106,9,125,0.2) !important;
    /* animation: cardHoverAnimation 0.6s ease-in-out forwards; */
}

.card-component-style:hover {
    animation: cardHoverAnimation 0.5s ease-in forwards;
}

.card-component-style .MuiCardContent-root {
    padding: 25px 20px !important;
}

.card-button-style {
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding-inline: 0 15px !important;
    color: #6A097D !important;
}

@media screen and (min-width: 900px) and (max-width: 1600px) {
    .healthcare-heading-text-color {
        font-size: 28px !important;
    }
    .healthcare-sub-heading-text-color {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 900px) {
    .healthcare-heading-text-color {
        font-size: 26px !important;
    }
    .healthcare-sub-heading-text-color {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 600px) {
    .healthcare-heading-text-color {
        font-size: 23px !important;
    }
    .healthcare-sub-heading-text-color {
        font-size: 15px !important;
    }
}