.cbc-main-item {
    margin: 50px auto !important;
}

.cbc-card-main-item {
    box-shadow: 0px 0px 20px 0px #00000012;
    padding: 30px 20px !important;
    border-radius: 5px;
    max-width: 450px !important;
    width: 450px !important;
}

.cbc-free-bg-item {
    background-color: white;
}

.cbc-premium-bg-item {
    background-color: #fdf8ff;
    border: 1px solid #6A097D;
}

.cbc-head-text {
    font-size: 22px;
    font-weight: 500;
    color: black;
}

.cbc-subhead-text {
    font-size: 15px;
    font-weight: 400;
    color: black;
}

.cbc-pricing-text {
    font-size: 30px;
    font-weight: 500;
}

.cbc-pricing-duration-text {
    font-size: 15px;
    font-weight: 400;
    color: black;
}

.cbc-started-outlined-btn {
    background-color: white !important;
    border: 1px solid #6A097D !important;
    border-radius: 5px !important;
    color: #6A097D !important;
    text-transform: none !important;
    padding: 8px 15px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.cbc-started-contained-btn {
    background-color: #6A097D !important;
    border-radius: 5px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 8px 15px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.cb-plans-subpoints-text {
    font-size: 18px;
    font-weight: 600;
    color: black;
}


.cb-plan-light-checkmark {
    width: 15px;
    height: 15px;
    object-fit: fill;
}

.cb-plan-points-text {
    font-size: 14px;
    font-weight: 500;
    color: black;
}


.cbc-addons-item {
    border: 1px solid rgba(106, 9, 125, 0.5);
    padding: 10px;
    border-radius: 8px;
}

.cbc-addons-span {
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.cbc-addons-highlighted-span {
    font-size: 14px;
    font-weight: 500;
    color: #6A097D;

}






/* -------------input------------- */

.cbc-input-main-item {
    background-color: #FDF9FF;
    padding: 50px 0px;
}

.cbc-input-card {
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px #00000012;
    padding: 20px 20px 40px 20px;
    max-width: 450px !important;
    width: 450px !important;
    background-color: white;

}

.cbc-in-maintext {
    font-size: 30px;
    font-weight: 500;
    color: #1F384C;
}

.cbc-in-subtext {
    font-size: 15px;
    font-weight: 500;
    color: #1F384C;
}

.cbc-input-icon-img {
    width: 25px;
    height: auto;
    object-fit: fill;
}

.cbc-input-icon-text {
    font-size: 20px;
    font-weight: 500;
    color: #1F384C;
}


.user-auth-dialog .MuiDialog-paperScrollPaper {
    width: 100%;
    max-width: 500px !important;
    border-radius: 10px;
}

.cbc-addon-card {
    margin: 5px auto;
    overflow: auto;
    max-height: 15vh
}


.cbc-addon-card::-webkit-scrollbar {
    width: 3px;
}

.cbc-addon-card-selected {
    border: 1px solid #6A097D;
}


/* -------------input------------- */

@media only screen and (min-width : 1536px) {
    .cbc-card-main-item {
        width: 410px;
        min-width: 410px;
    }

    .cbc-input-card {
        width: 410px;
        min-width: 410px;
    }
}


@media only screen and (max-width : 1536px) and (min-width : 1440px) {
    .cbc-card-main-item {
        max-width: 420px !important;
        width: 420px !important;
        min-width: 420px !important;
    }

    .cbc-input-card {
        max-width: 420px !important;
        width: 420px !important;
        min-width: 420px !important;
    }
}


@media only screen and (max-width : 1439px) and (min-width : 1200px) {
    .cbc-card-main-item {
        max-width: 435px !important;
        width: 435px !important;
        min-width: 435px;
    }

    .cbc-input-card {
        max-width: 435px !important;
        width: 435px !important;
        min-width: 435px;
    }
}

@media only screen and (max-width : 1199px) and (min-width : 900px) {
    .cbc-card-main-item {
        max-width: 450px !important;
        width: 450px !important;
        min-width: 450px;
    }

    .cbc-input-card {
        max-width: 450px !important;
        width: 450px !important;
        min-width: 450px;
    }
}

@media only screen and (max-width : 900px) and (min-width : 600px) {
    .cbc-card-main-item {
        max-width: 450px !important;
        width: 450px !important;
        min-width: 450px;
    }

    .cbc-input-card {
        max-width: 450px !important;
        width: 450px !important;
        min-width: 450px;
    }
}


@media only screen and (max-width : 600px) and (min-width : 0px) {
    .cbc-card-main-item {
        width: 97% !important;
        max-width: 97% !important;
        min-width: 97% !important;
    }

    .cbc-input-card {
        width: 90% !important;
        max-width: 90% !important;
        min-width: 90% !important;

    }
}