.caid-main-head-text {
    font-size: 33px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.caid-sub-head-text {
    font-size: 23px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.caid-global-field-head-text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.caid-bg-img {
    width: 100% !important;
    height: 100% !important;
}

.caid-close-btn {
    position: absolute !important;
    right: 5px !important;
    top: 5px !important;
    background-color: rgb(247, 247, 247) !important;
}

.caid-main-content-item {
    margin: 50px auto 0px !important;
}

.caid-img-item {
    margin-bottom: 10px !important;
}

.caid-head-item {
    margin: 10px 0px !important;
}

.caid-sub-head-text {
    margin: 10px 0px !important;
}

.caid-field-item {
    margin: 30px 0px 0px !important;
}

.caid-fields-comman-sub-items {
    margin: 0px 0px 20px !important;
}

.caid-custom-textfield .MuiInputLabel-root {
    color: rgb(146, 146, 146) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.caid-custom-textfield .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.caid-custom-textfield .MuiInput-underline:after {
    border-bottom: 1px solid #6A097D !important;
}

.caid-custom-textfield .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #6A097D !important;
}


@media only screen and (min-width: 0px) and (max-width: 599px) {

    .caid-main-content-item {
        margin: 15px auto 0px !important;
    }

    .caid-sub-head-text {
        font-size: 18px !important;
    }

}