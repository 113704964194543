.gcta-btn {
    background-color: white !important;
    color: #6A097D !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    padding: 5px 15px !important;
}


.gcta-btn-text-item,
.gcta-head-text-item,
.gcta-subhead-item {
    margin: 20px auto !important;
    /* text-align: center !important; */
}

.gcta-head-text {
    font-size: 32px !important;
    font-weight: 500 !important;
    color: white !important;
}

.gcta-subhead-text {
    color: white !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

@media only screen and (max-width: 400px) {
    .gcta-head-text {
        font-size: 10px !important;
        font-weight: 500 !important;
        color: white;
    }

    .gcta-subhead-text {
        color: white;
        font-size: 15px !important;
        font-weight: 400 !important;
    }
}

@media only screen and (max-width: 600px) {
    .gcta-head-text {
        font-size: 30px !important;
        font-weight: 500 !important;
        color: white;
    }

    .gcta-subhead-text {
        color: white;
        font-size: 17px !important;
        font-weight: 400 !important;
    }
}

@media only screen and (max-width: 900px) {
    .gcta-head-text {
        font-size: 21px !important;
        font-weight: 500 !important;
        color: white;
    }

    .gcta-subhead-text {
        color: white;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
}

@media only screen and (max-width: 1200px) {
    .gcta-head-text {
        font-size: 28px !important;
        font-weight: 500 !important;
        color: white;
    }

    .gcta-subhead-text {
        color: white;
        font-size: 17px !important;
        font-weight: 400 !important;
    }
}

@media only screen and (max-width: 1600px) {
    .gcta-head-text {
        font-size: 33px !important;
        font-weight: 500 !important;
        color: white;
    }

    .gcta-subhead-text {
        color: white;
        font-size: 19px !important;
        font-weight: 400 !important;
    }
}