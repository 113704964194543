.front-hero-section-container {
    background: linear-gradient(180deg, #EBE2F1 0%, #FFF 100%) !important;
    backdrop-filter: blur(12px) !important;
    background-size: cover !important;
}

.content-point-item {
    margin: 0px 0px 30px !important;
}

.content-point-text {
    font-size: 17px !important;
    font-weight: 450 !important;
}

.verified-meta-tech-span {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: black !important;
    display: flex;
    align-items: center;
    gap: 5px;
}


.hero-heading-text-style {
    font-size: 50px !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    background: linear-gradient(to right bottom, rgba(51, 51, 51, 1) 70%, rgba(148, 148, 148, 1) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    margin: 0px 0px 20px;
}

.hero-heading2-text-style {
    font-size: 28px !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    /* background: linear-gradient(to right bottom, rgba(51, 51, 51, 1) 70%, rgba(148, 148, 148, 1) 100%) !important; */
    color: rgba(51, 51, 51, 1) !important;
    /* -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important; */
    margin: 0px;
}

.hero-dark-button-style {
    background-color: #6A097D !important;
    /* background-color: #0A6ADD !important; */
    /* box-shadow: 0px 0px 4px #0A6ADD !important; */
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4) !important; */
    color: white !important;
    text-transform: none !important;
    padding: 10px 36px !important;
    border-radius: 10px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    width: 200px !important;
    margin-right: 30px !important;
}

.hero-light-button-style {
    /* background: linear-gradient(to right bottom, #F7F6FD 60%, #E3EBF5 100%) !important; */

    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4) !important; */
    /* border: 1px solid #0A6ADD !important; */
    color: #6A097D !important;
    border: 1px solid #6A097D !important;
    text-transform: capitalize !important;
    padding: 10px 36px !important;
    border-radius: 10px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    width: 200px !important;
    text-transform: none !important;
}

.front-hero-main-section {
    margin: 0 auto !important;
    padding: 50px 0px !important;
}

.hero-section-right-container-button {
    text-transform: uppercase !important;
    padding: 6px 30px !important;
    border-radius: 5px !important;
    color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
    white-space: nowrap !important;
}

.right-section-image-container {
    width: 100% !important;
    border-radius: 10px !important;
    /* max-width: 600px !important; */
    max-width: 100% !important;
    object-fit: scale-down !important;
}

.hero-section-right-container-button:hover {
    background-color: #6A097D !important;
    color: white !important;
}

.left-image-item-style {
    text-align: start !important;
    padding-left: 48px !important;
}

.right-image-item-style {
    text-align: end !important;
    padding-left: 0px !important;
}

.right-image-button-item {
    margin-top: -30px !important;
    z-index: 1;
    background-color: #FFFFFF !important;
    width: 100% !important;
    max-width: 600px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4) !important;
    border-radius: 10px !important;
    padding: 10px !important;
    overflow: auto !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
}

.vf-meta-meta-img {
    width: auto;
    height: 30px;
}

.lp-divider-item {
    display: block !important;
}

.hsc-iframe-item {
    width: 100% !important;
}

.hsc-iframe {
    height: 500px !important;
    max-height: 500px !important;
    min-width: 100%;
    width: 100% !important;
    border-radius: 10px !important;
}


@media only screen and (min-width: 1600px) and (max-width: 1850px) {
    .hero-heading-text-style {
        font-size: 40px !important;
    }

    .hero-heading2-text-style {
        font-size: 28px !important;
    }

    .content-point-text {
        font-size: 17px !important;
        /* font-weight: 500 !important; */
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .hero-heading-text-style {
        font-size: 34px !important;
    }

    .hero-heading2-text-style {
        font-size: 28px !important;
    }

    .hero-dark-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
        margin-right: 20px !important;
    }

    .hero-light-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
    }

    .right-section-image-container {
        /* max-width: 500px !important; */
        max-width: 100% !important;
    }

    .content-point-text {
        font-size: 16px !important;
        /* font-weight: 500 !important; */
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .hero-heading-text-style {
        font-size: 32px !important;
    }

    .hero-heading2-text-style {
        font-size: 28px !important;
    }

    .hero-dark-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
        margin-right: 20px !important;
    }

    .hero-light-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
    }

    .right-section-image-container {
        /* max-width: 500px !important; */
        max-width: 100% !important;
    }

    .left-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
    }

    .right-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
    }

    .hero-section-right-container-button {
        padding: 6px 20px !important;
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .front-hero-main-section {
        padding: 40px 15px !important;
    }

    .hero-heading-text-style {
        font-size: 30px !important;
    }

    .hero-heading2-text-style {
        font-size: 25px !important;
    }

    .hero-dark-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
        margin-right: 20px !important;
    }

    .hero-light-button-style {
        padding: 8px 32px !important;
        font-size: 14px !important;
        width: 180px !important;
    }


    .left-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
    }

    .right-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
    }

    .hero-section-right-container-button {
        padding: 6px 20px !important;
        font-size: 14px !important;
    }

}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .right-image-item-style {
        text-align: end !important;
        margin: 60px auto !important;
        padding: 0px !important;
        max-width: 800px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .front-hero-main-section {
        padding: 40px 30px !important;
    }

    .hero-heading-text-style {
        font-size: 27px !important;
    }

    .hero-heading2-text-style {
        font-size: 22px !important;
    }

    .hero-dark-button-style {
        padding: 8px 24px !important;
        font-size: 14px !important;
        width: 150px !important;
        margin-right: 15px !important;
    }

    .hero-light-button-style {
        padding: 8px 24px !important;
        font-size: 14px !important;
        width: 150px !important;
    }

    .left-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
    }

    .right-image-item-style {
        text-align: center !important;
        padding-left: 0px !important;
        max-width: 100% !important;
        margin: 0 auto !important;
    }

    .right-section-image-container {
        max-width: 100% !important;
        width: 100% !important;
        height: 500px !important;
        border-radius: 10px !important;
        object-fit: cover !important;
    }

    .right-image-button-item {
        width: 85% !important;
    }

    .content-point-text {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .verified-meta-tech-span {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: black !important;
    }

    .hero-section-right-container-button {
        padding: 6px 20px !important;
        font-size: 14px !important;
    }

    .front-hero-section-container {
        /* background: url('./../../assests/AiWhatsapp/wp-bg-gradiant-img.png'); */
        background-repeat: no-repeat !important;
        padding: 120px 0px 120px 0px !important;
        background-size: cover !important;
        background-position: center right !important;
        height: 100%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 398px) {
    .vf-meta-meta-img {
        width: 80px;
        height: auto;
    }

    .front-hero-main-section {
        padding: 40px 15px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 360px) {
    .lp-divider-item {
        display: none !important;
    }

    .vf-meta-meta-img {
        width: 120px;
        height: auto;
    }

    .hero-global-btn {
        margin: 5px 5px !important;
    }

    .verified-meta-tech-span {
        font-size: 16px !important;
        font-weight: 600 !important;
    }
}