.HWU-header-container {
  text-align: center;
  margin-bottom: 45px !important;
  /* padding: 0px 55px; */
}

.HWU-header-text {
  font-weight: 500;
  font-size: 40px;
  display: inline-block;
  padding-bottom: 7.5px;
  border-bottom: 3px solid rgba(106, 9, 125, 0.8);
}

.HWU-purple-word {
  color: #6a097d;
}

.HWU-titleText {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (min-width: 1900px) {
  .HWU-header-text {
    font-size: 40px;
  }
  .HWU-titleText {
    font-size: 21px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .HWU-header-text {
    font-size: 36px;
  }
  .HWU-titleText {
    font-size: 20px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .HWU-header-text {
    font-size: 30px;
  }
  .HWU-titleText {
    font-size: 18px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .HWU-header-text {
    font-size: 30px;
  }
  .HWU-titleText {
    font-size: 17px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .HWU-header-text {
    font-size: 25px;
  }
  .HWU-titleText {
    font-size: 18px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .HWU-header-container {
    /* padding: 0px 35px; */
    margin-bottom: 35px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .HWU-header-container {
    /* padding: 0px 25px; */
    margin-bottom: 35px !important;
  }
}
