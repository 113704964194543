.chb-main-container {
    background-image: url("../../assests/chatbotFrame.png");
    /* background-color: #6A097D; */
    /* Specify the path to your image */
    background-size: cover;
    background-position: center;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    /* margin-top: 15px; */
    padding: 40px 0px;
}

.chb-main-container-item {
    margin: 60px auto 10px !important;
    /* margin-top: 60px !important; */
    /* margin-bottom: 40px !important; */
}

.chb-hero-container {
    align-items: center;
    justify-content: space-between;
}

.chb-hero-img-containerr {
    justify-content: start;
}

.chb-hero-img {
    max-width: 720px;
    width: 100%;
    display: flex;
    margin: auto;
}

.chb-hero-sub-heading {
    font-weight: 600 !important;
    font-size: 22px !important;
    color: #fff;
}

.chb-hero-meta-img {
    width: 40px;
    height: 26px;
    margin-right: 6px;
    margin-left: 6px;
}

.chb-hero-verified-img {
    margin-left: 6px;
}

.chb-sub-heading-container {
    align-items: center;
}

.chb-hero-main-heading {
    font-weight: 500 !important;
    font-size: 40px !important;
    color: #fff;
    line-height: 50px !important;
}

.chb-hero-content {
    font-weight: 400 !important;
    font-size: 19px !important;
    color: #fff;
}

.chb-hero-button {
    background: #fff !important;
    color: #6a097d !important;
    font-weight: 550 !important;
    font-size: 22px !important;
    text-transform: none !important;
    padding: 7px 15px !important;
    border-radius: 8px !important;
}

.chb-hero-button:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
    background-color: #f4f4f5 !important;
}

.chb-hero-main-heading-item {
    margin-top: 20px !important;
}

/* .chb-hero-content-items {
    margin-top: 20px !important;
} */

.chb-hero-button-items {
    margin-top: 25px !important;
}

.chb-no-credit-typo {
    font-size: 17px !important;
    color: #fff !important;
    font-weight: 500 !important;
}

.chb-hero-content-item {
    margin-top: 0px !important;
}

.chb-no-credit-item {
    margin-top: 12px;
}

.chb-image-animation-effect {
    animation: showImage 0.9s ease-out;
}

@keyframes showImage {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

.chb-slideRight-effect {
    animation: slideRight 1s ease-out forwards;
}

@keyframes slideRight {
    from {
        transform: translateX(-5%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.chb-slideLeft-effect {
    animation: slideLeft 1s ease-out forwards;
}

@keyframes slideLeft {
    from {
        transform: translateX(5%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen and (min-width: 1900px) {
    .chb-hero-main-heading {
        font-size: 45px !important;
    }

    .chb-hero-sub-heading {
        font-size: 25px !important;
    }

    .chb-hero-content {
        font-size: 19px !important;
    }

    .chb-hero-button {
        font-size: 18px !important;
    }

    .chb-no-credit-typo {
        font-size: 16px !important;
    }

    .chb-hero-img {
        max-width: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1900px) {
    .chb-hero-main-heading {
        font-size: 36px !important;
    }

    .chb-hero-sub-heading {
        font-size: 20px !important;
    }

    .chb-hero-content {
        font-size: 18px !important;
    }

    .chb-hero-button {
        font-size: 18px !important;
    }

    .chb-no-credit-typo {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {

    /* .chb-main-container {
        background-image: url("../../assests/chatbotbig-tab-frame.png");
        padding: 10px 0px;
    } */
    .chb-hero-main-heading {
        font-size: 42px !important;
    }

    .chb-hero-content {
        font-size: 20px !important;
    }

    .chb-hero-sub-heading {
        font-size: 20px !important;
    }

    .chb-hero-button {
        font-size: 18px !important;
    }

    .chb-no-credit-typo {
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .chb-main-container {
        background-image: url("../../assests/chatbot-tab-hero-bg.png");
        padding: 10px 0px;
    }

    .chb-hero-main-heading {
        font-size: 35px !important;
    }

    .chb-hero-content {
        font-size: 18px !important;
    }

    .chb-hero-sub-heading {
        font-size: 18px !important;
    }

    .chb-hero-button {
        font-size: 15px !important;
    }

    .chb-no-credit-typo {
        font-size: 13px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .chb-hero-main-heading {
        font-size: 30px !important;
    }

    .chb-hero-content {
        font-size: 15px !important;
    }

    .chb-hero-sub-heading {
        font-size: 18px !important;
    }

    .chb-hero-button {
        font-size: 13px !important;
    }

    .chb-no-credit-typo {
        font-size: 13px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .chb-main-container {
        margin-top: 25px !important;
    }

    .chb-lower-frame-item {
        margin-top: 40px !important;
    }

    .chb-main-container-item {
        margin: 20px auto 0px !important;
    }

    /* .chb-hero-sub-heading {
        font-size: 20px !important;
    } */
    .chb-hero-meta-img {
        width: 31px;
        height: 21px;
    }

    .chb-hero-main-heading-item {
        margin-top: 10px !important;
    }

    .chb-hero-content-items {
        margin-top: 15px !important;
    }

    .chb-hero-button-items {
        margin-top: 25px !important;
    }

    .chb-no-credit-item {
        margin-top: 5px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .chb-main-container {
        background-image: url("../../assests/chatbot-mobile-frame.png");
        padding: 10px 0px;
        margin-top: 8px;
    }

    .chb-lower-frame-item {
        margin-top: 15px !important;
    }

    .chb-main-container-item {
        margin: 40px auto 0px !important;
    }

    .chb-hero-main-heading-item {
        margin-top: 10px !important;
    }

    .chb-hero-content-items {
        margin-top: 15px !important;
    }

    .chb-hero-meta-img {
        width: 25px;
        height: 17px;
    }

    .chb-hero-verified-img {
        width: 20px;
        height: 20px;
    }

    .chb-hero-main-heading {
        line-height: normal !important;
    }

    .chb-hero-button {
        border-radius: 5px !important;
    }

    .chb-hero-button-items {
        margin-top: 20px !important;
    }

    .chb-no-credit-item {
        margin-top: 5px !important;
    }
}