.aiw-main-section-item {
    background: url('./../../assests/AiWhatsapp/wp-bg-gradiant-img.png');
    background-repeat: no-repeat !important;
    padding: 150px 0px 0px 0px !important;
    background-size: cover !important;
    background-position: bottom !important;
}

.aiw-head-section-item {
    margin: 0 auto !important;
}

.aiw-meta-img {
    width: 150px;
    height: auto;
    object-fit: fill;
}

.aiw-head-text {
    font-size: 40px !important;
    font-weight: 700 !important;
    color: #343434 !important;
}

.aiw-sub-head-text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.aiw-gen-ai-btn {
    background-color: #0A6ADD !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    text-transform: none !important;
    box-shadow: 0px 0px 4px 0px #0A6ADD !important;
}


.aiw-book-demo-btn {
    background-color: white !important;
    color: #0A6ADD !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 1px solid #0A6ADD !important;
    text-transform: none !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 4px 0px #0A6ADDCC !important;
}

.aiw-right-img {
    width: 400px;
    height: auto;
    object-fit: fill;
}



@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .aiw-head-text {
        font-size: 35px !important;
        font-weight: 700 !important;
        color: #343434 !important;
    }

    .aiw-sub-head-text {
        font-size: 17px !important;
        font-weight: 600 !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .aiw-head-text {
        font-size: 35px !important;
        font-weight: 700 !important;
        color: #343434 !important;
    }

    .aiw-sub-head-text {
        font-size: 17px !important;
        font-weight: 600 !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .aiw-head-text {
        font-size: 35px !important;
        font-weight: 700 !important;
        color: #343434 !important;
    }

    .aiw-sub-head-text {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .aiw-main-section-item {
        padding: 120px 0px 0px 0px !important;
    }

    .aiw-right-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .aiw-head-text {
        font-size: 30px !important;
        font-weight: 700 !important;
        color: #343434 !important;
    }

    .aiw-sub-head-text {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }
}