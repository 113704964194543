.experince-component-container {
    background: url('./../../assests/Pages/Events/experience-bg.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.experience-component-item-style {
    padding: 60px 0px !important;
}

.event-name-style {
    color: white !important;
    font-size: 42px !important;
    font-weight: 700 !important;
    letter-spacing: 4px !important;
}

.arrow-button-global-style {
    border: 1px solid white !important;
    padding: 4px !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.arrow-button-global-style:hover {
    background-color: rgba(0,0,0,0.25) !important;
}

.image-dot-style {
    height: 10px !important;
    width: 10px !important;
    background-color: rgba(255, 255, 255, 0.6) !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.selected-image-dot-style {
    height: 14px !important;
    width: 14px !important;
    background-color: rgba(255, 255, 255, 1) !important;
    cursor: pointer !important;
    border-radius: 50% !important;
}

.current-slide {
    width: 40%;
    max-height: 850px;
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease-in;
    border-radius: 10px;
    z-index: 2;
}

.current-slide.fade {
    animation: fadeIn 0.75s ease;
}

.image-wrapper.fade {
    animation: fadeIn 1s ease;
}

.image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 10px;
}

.prev-wrapper {
    transform: translateX(30%) !important;
}

.next-wrapper {
    transform: translateX(-30%) !important;
}

.prev-slide, .next-slide {
    width: 100%;
    max-height: 600px;
    opacity: 0.4;
    transform: scale(1);
    transition: all 0.5s ease-in;
    border-radius: 10px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .event-name-style {
        font-size: 38px !important;
        letter-spacing: 3px !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .event-name-style {
        font-size: 32px !important;
        letter-spacing: 2.5px !important;
    }

    .prev-wrapper {
        transform: translateX(40%) !important;
    }
    
    .next-wrapper {
        transform: translateX(-40%) !important;
    }

    .current-slide {
        width: 50%;
        max-height: 800px;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .event-name-style {
        font-size: 28px !important;
        letter-spacing: 2.5px !important;
    }

    .prev-wrapper {
        transform: translateX(50%) !important;
    }
    
    .next-wrapper {
        transform: translateX(-50%) !important;
    }

    .current-slide {
        width: 60%;
        max-height: 750px;
    }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .event-name-style {
        font-size: 24px !important;
        letter-spacing: 2px !important;
    }

    .prev-wrapper {
        transform: translateX(50%) !important;
    }
    
    .next-wrapper {
        transform: translateX(-50%) !important;
    }

    .current-slide {
        width: 100%;
        max-width: 90vw !important;
        max-height: 500px;
    }
}