.CB-chatBotFeatures {
  margin-top: 100px !important;
  margin-bottom: 160px !important;
}

.CB-chatBotStats {
  margin-bottom: 90px !important;
}

.chatbot-faq-section {
  /* background: linear-gradient(
    180deg,
    rgba(247, 233, 255, 1) 44%,
    rgba(247, 233, 255, 1) 66%,
    rgba(247, 233, 255, 1) 87%,
    rgba(255, 255, 255, 1) 100%
  ); */
  background-color: white !important;
  height: auto;
  padding-bottom: 100px !important;
  /* margin: 60px 0px 120px !important; */
  margin: 0px 0px 60px !important;
}


.brsw-new-head-section-main-item {
  margin: 0px auto 30px !important;
}

.brsw-new-expertise-section-main-item {
  margin: 100px auto 70px !important;
}

.brsw-new-customer-section-main-item {
  margin: 20px auto 50px !important;
}

.brsw-new-liveai-section-main-item {
  margin: 190px auto 0 !important;
}

.brsw-new-common-section-main-item {
  margin: 50px auto !important;
}

.brsw-new-aisimplified-section-main-item {
  margin: 0px auto 120px !important;
}

.chatbot-card-section {
  margin: 0px auto 0px !important;
}

.cb-omini-section-item {
  margin: 100px 0px !important;
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .brsw-new-expertise-section-main-item {
    margin: 450px auto 100px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .brsw-new-expertise-section-main-item {
    margin: 250px auto 50px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .brsw-new-expertise-section-main-item {
    margin: 70px auto 50px !important;
  }
}

@media only screen and (min-width: 650px) and (max-width: 1200px) {
  .CB-chatBotStats {
    margin: 60px auto 35px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 650px) {
  .CB-chatBotStats {
    margin-bottom: 35px !important;
  }
}