.side-by-side-main-item-section {
    /* background: linear-gradient(to right bottom, #f6f3ff, #cd9cf2) !important; */
    /* max-height: 100vh; */
    height: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
}

.side-by-side-item-section {
    /* background: linear-gradient(to right bottom, #f6f3ff, #cd9cf2) !important; */
    /* max-height: 100vh; */
    height: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
}

.side-by-side-main-hero-section {
    max-width: 1300px !important;
    padding: 120px 10px;
    height: 100%;
    border-radius: 20px;
    margin: 40px auto 0 !important;
}

.side-by-side-main-section {
    max-width: 1300px !important;
    height: 100%;
    border-radius: 20px;
    margin: 40px auto 0 !important;
    padding-bottom: 20px;
}

.side-by-side-heading-text-style {
    font-size: 36px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.side-by-side-heading2-text-style {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #454545 !important;
}

.side-by-side-description-text-style {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.side-by-side-sub-heading-text-style {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.sbs-global-btn-style {
    font-size: 15px !important;
    font-weight: 500 !important;
    width: auto !important;
}

.side-by-side-round-btn-style {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background-color: #F2C970 !important;
    color: #000000 !important;
    border-radius: 30px !important;
    padding: 10px !important;
    width: auto;
}

.side-by-side-btn-style {
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background-color: #F2C970 !important;
    color: #000000 !important;
    padding: 10px !important;
    width: auto;
    border-radius: 10px !important;
}

.side-by-side-image-style {
    width: 100% !important;
    object-fit: contain;
    max-width: auto !important;
    border-radius: 20px;
    height: 500px;
    max-height: 500px !important;
    height: auto;
}

.side-by-side-left-image-style {
    height: 100%;
    text-align: center !important;
}

.side-by-side-right-image-style {
    text-align: end !important;
}

.sbs-point-text-item {
    margin: 0px 0px 25px !important;
}

.sbs-point-icons {
    max-width: 15px !important;
    width: 15px !important;
}

.sbs-point-text-item {
    max-width: calc(100% - 30px) !important;
    width: calc(100% - 30px) !important;
}

.sbs-credit-span {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: rgb(172, 172, 172) !important;
}


@media screen and (max-width: 1650px) {
    .side-by-side-heading-text-style {
        font-size: 32px !important;
    }

    .side-by-side-heading2-text-style {
        font-size: 22px !important;
    }

    /* .side-by-side-image-style {
        max-width: 400px !important;
    } */
}

@media only screen and (min-width: 0px) and (max-width: 1240px) {
    .side-by-side-main-section {
        max-width: auto;
        width: auto;
    }


    .side-by-side-image-style {
        width: 100% !important;
        object-fit: contain;
        max-width: auto !important;
        border-radius: 20px;
        height: 420px;
        max-height: 420px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    /* .side-by-side-main-section {
        padding: 30px !important;
    } */

    .side-by-side-heading-text-style {
        font-size: 25px !important;
    }

    .side-by-side-heading2-text-style {
        font-size: 17px !important;
    }

    .side-by-side-description-text-style {
        font-size: 16px !important;
    }

    .side-by-side-sub-heading-text-style {
        font-size: 14px !important;
    }

    /* .side-by-side-image-style {
        max-width: 450px !important;
    } */
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
    /* .side-by-side-main-section {
        padding: 30px !important;
    } */

    .side-by-side-heading-text-style {
        font-size: 25px !important;
    }

    .side-by-side-heading2-text-style {
        font-size: 17px !important;
    }

    .side-by-side-description-text-style {
        font-size: 16px !important;
    }

    .side-by-side-sub-heading-text-style {
        font-size: 14px !important;
    }

    .side-by-side-left-image-style {
        text-align: center !important;
    }

    .side-by-side-right-image-style {
        text-align: center !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    /* .side-by-side-main-section {
        padding: 30px !important;
    } */

    .side-by-side-heading-text-style {
        font-size: 23px !important;
    }

    .side-by-side-heading2-text-style {
        font-size: 16px !important;
    }

    .side-by-side-description-text-style {
        font-size: 15px !important;
    }

    .side-by-side-sub-heading-text-style {
        font-size: 14px !important;
    }

    .side-by-side-left-image-style {
        text-align: center !important;
    }

    .side-by-side-right-image-style {
        text-align: center !important;
    }

    .side-by-side-item-section {
        padding-left: 20px;
        padding-right: 20px;
    }
}