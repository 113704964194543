.healthcare-footer-text-color {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    line-height: 30px !important;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .healthcare-footer-text-color {
        font-size: 28px !important;
    }
}

@media screen and (max-width: 900px) {
    .healthcare-footer-text-color {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 600px) {
    .healthcare-footer-text-color {
        font-size: 23px !important;
    }
}