.cw-subhead-text {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
}

.cw-gradiant-head-text {
    font-size: 35px !important;
    font-weight: 500 !important;
    color: white;
}

.cw-ai-messaging-btn {
    background-color: transparent !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 3px !important;
    border: 1px solid white !important;
    text-transform: none !important;
}

.cw-demo-btn {
    background-color: white !important;
    color: #3B78CE !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 3px !important;
    text-transform: none !important;
}