.cbPreviewIframeItem {
    margin: 0px auto !important;
    max-width: 425px !important;
    width: 100% !important;
}

.cbPreviewIframeParentDiv {
    height: 100%;
    padding: 5px 5px !important;
}

.chatbotNavbarMainItem {
    border-radius: 15px 15px 0px 0px !important;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid #DEDEDE;
}

.chatbotChatMainSectionItem {
    padding: 20px !important;
}

.chatbotChatIconDiv {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    align-self: self-start;
    display: flex;
    object-fit: cover;
    object-position: center;
}

.parentbotMsgBoxStyle .botMsgBoxStyle {
    background: unset !important;
    max-height: unset !important;
    margin: 0 !important;
}

.parentbotMsgBoxStyle {
    position: relative;
}

.userReactionStyle {
    position: absolute;
    bottom: -17px;
    right: 9px;
    background: #fff;
    border: 1px solid #e7dbdb;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.botReactionStyle {
    position: absolute;
    bottom: -17px;
    left: 9px;
    background: #fff;
    border: 1px solid #e7dbdb;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.chatbotPreviewMessageboxMainItem {
    margin: 0px 0px 0px !important;
}

.chatbot_preview_arrow_item {
    border-radius: 10px !important;
    max-width: 45px !important;
    width: 45px !important;
    height: 45px !important;
    max-height: 45px !important;
}

.chatbotPreviewMessageboxMainContainer {
    border-radius: 8px !important;
    box-shadow: 1px 1px 5px -2px rgba(247, 233, 255, 1) !important;
}

.sendMsgTextField .MuiInputBase-input {
    padding: 11px 15px !important;
    color: black;
    font-size: 15px !important;
    font-weight: 500 !important;
}


.fullHeight {
    height: 100% !important;
}
.centerAlignment {
    align-items: center;
    justify-content: center;
}
.primaryFontSize {
    font-size: 15px !important;
    font-weight: 600 !important;
}
.clickable {
    cursor: pointer !important;
}

/* react markdown*/
.reactMarkdownStyle {
    overflow: unset;
    word-break: break-word;
}

.reactMarkdownStyle ul {
    padding: 0px 15px;
    list-style-type: circle !important;
}

.reactMarkdownStyle ol {
    padding: 10px 15px !important;
    white-space: normal !important;
}

.reactMarkdownStyle ol:last-child {
    padding: 10px 15px 0px !important;
}

.reactMarkdownStyle pre ol {
    padding: 10px 15px;
}

.reactMarkdownStyle ol li{
    margin-top: 10px !important;
}

.reactMarkdownStyle blockquote {
    display: flex !important;
    margin: unset !important;
}

.reactMarkdownStyle ol li:last-child{
    margin-block: 10px !important;
}

.reactMarkdownStyle ul li {
    display: list-item;
    text-align: -webkit-match-parent;
    padding-top: 0px !important;
    margin-bottom: 5px !important;
}

.reactMarkdownStyle ul li:last-child{
    margin-block: 10px !important;
}

.reactMarkdownStyle pre {
    width: 100% !important;
    overflow: auto !important;
    white-space: pre-wrap !important;
    word-break: break-all !important;
}

.reactMarkdownStyle pre,code {
    width: 100% !important;
    overflow: auto !important;
    white-space: pre-line !important;
    word-break: break-word !important;
}

.reactMarkdownStyle strong {
    margin: 10px 0px !important;
}

.reactMarkdownStyle img{
    height: auto !important;
    width: 100% !important;
    object-fit: fill !important;
    margin-left: 5px !important;
    margin-block: 10px 15px !important;
    display: block !important;
}

.reactMarkdownStyle p {
    margin: 0px !important;
}

.chat_reply_text {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.chat_reply_text p {
    margin: 2px 0px !important;
}

.chat_message_reply_left_item {
    max-width: calc(100% - 59px) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.chat_img_reply_item {
    max-width: 50px !important;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.chat_reply_meadia_text {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    margin-bottom: 5px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000 !important;
}

.chat_media_reply_text_item {
    max-width: calc(100% - 40px) !important;
    flex-wrap: nowrap !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.chat_chatbot_text_hidden_label {
    text-overflow: ellipsis !important;
    flex-wrap: nowrap !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.chat_media_reply_img_item {
    position: absolute;
    top: 0;
    right: 0;
    height: 100% !important;
    max-width: 50px !important;
    width: 50px !important;
}