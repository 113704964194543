.gb-tab-main-item {
    background-color: rgb(255, 255, 255);
    border-radius: 10px !important;
    padding: 20px !important;
    max-width: 1500px !important;
    margin: 0 auto !important;
}

.gb-tab-main-container {
    padding: 24px;
    background-color: #f5f5f5;
}

.gb-tab-header {
    text-align: center;
    margin-bottom: 16px;
}

.gb-tab-main-heading {
    font-weight: bold;
    font-size: 40px !important;
    font-weight: 500 !important;
}

.gb-tab-button {
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    background-color: #F7E9FF !important;
    color: black !important;
    border-radius: 5px 5px 0px 0px !important;
    padding: 8px 16px !important;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}

.gb-tab-button-active {
    background-color: #6A097D !important;
    color: white !important;
    font-weight: 500 !important;
}

.gb-tab-content {
    text-align: center;
    background-color: #fcf7ff;
    border-radius: 10px !important;
    padding: 50px 80px !important;
    margin: 0 auto !important;
}

.gb-tab-head-text {
    font-weight: 600;
    margin-bottom: 8px;
}

.gb-tab-caption {
    color: #555;
}


/* .gb-tab-head-text-item{
    margin: 0px 0px 10px !important;
}
.gb-tab-subhead-text-item{
    margin: 10px 0px 10px !important;
}
.gb-tab-subhead-text-item{
    margin: 0px 0px 10px !important;
} */

.gb-tab-content-item {
    margin: 50px 0px !important;
}

.gb-tab-head-text-item h2 {
    font-size: 35px;
    font-weight: 600;
    color: black;
}

.gb-tab-subhead-text-item h3 {
    font-size: 25px;
    font-weight: 500;
    color: black;
}


.gb-tab-caption-text {
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.gb-tab-content-item {
    text-align: start !important;
}

.table-data-img {
    max-width: 400px;
    width: 100%;
    height: auto;
    object-fit: fill;
}

.table-data-img-first {
    max-width: 600px;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .gb-tab-main-item {
        border-radius: 10px !important;
        padding: 20px !important;
        max-width: 90% !important;
        margin: 0 auto !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: rgb(254, 243, 255) !important;
    }

    /* .mb-tab-bg {
        padding: 25px !important;
        border-radius: 10px;
    } */

    .mb-tab-caption-text {
        text-align: center;
        margin: 0px 0px 10px !important;
    }

    .mb-tab-subhead-text {
        text-align: center;
        margin: 10px 0px 0px !important;
    }

    .mb-tab-head-text {
        text-align: center;
        margin: 20px 0px 20px !important;
    }

    .mb-tab-caption-text span {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: black !important;
    }

    .mb-tab-subhead-text span {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: black !important;
    }

    .mb-tab-head-text span {
        font-size: 17px !important;
        font-weight: 600 !important;
        color: black !important;
    }
}