.auth-email-textfield {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px auto !important;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25) !important;
}

.auth-continue-btn {
    background: #6A097D !important;
    color: white !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    border-radius: 3px !important;
    height: 56px !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.continueWithButton {
    border: 1.125pt solid #CDCDCD !important;
    border-radius: 15px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 52px !important;
    min-height: 52px !important;
    max-height: 52px !important;
    width: 100% !important;
    text-transform: unset !important;
    margin: 0px 0px 10px !important;
    justify-content: center !important;
    color: black !important;
    padding: 0 !important;
    height: 56px !important;
}

.recaptchaBlockPopup {
    margin-top: 20px !important;
    transform: scale(1.357);
}

.mainRecaptchaBlock {
    display: flex;
    justify-content: center;
}

.verifucation-login-btn {
    font-size: 17px !important;
    font-weight: 500 !important;
    background-color: #6A097D !important;
    text-transform: none !important;
    border-radius: 5px !important;
    color: white !important;
    padding: 14px 15px !important;
}

.editIconStyle {
    position: absolute !important;
    right: 18px;
}

.otpInputError {
    font-size: 16px !important;
    width: 60px !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border: 1px solid #FF0000;
    outline: none;
    text-align: center;
    justify-content: center
}

.inputOtpStyle {
    font-size: 16px !important;
    width: 60px !important;
    height: 40px !important;
    margin: 4px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: none;
    text-align: center;
    justify-content: center
}

.custom-no-border:hover {
    border: none !important;
    outline: none !important;
}

.custom-no-border .MuiInputBase-root.Mui-focused {
    border: none !important;
}

.custom-no-border .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
}