.CF-mainContainer {
  justify-content: center;
}

.CF-header-container {
  text-align: center;
  margin-bottom: 25px;
}

.CF-header-text {
  font-weight: 600;
  font-size: 40px;
  line-height: 54.47px;
  text-align: center;
  display: inline-block;
  padding-bottom: 7.5px;
  border-bottom: 4px solid rgba(106, 9, 125, 0.8);
}

.CF-purple-word {
  color: #6a097d;
}

.CF-featuresContainer {
  align-self: center;
}

.CF-FeaturesBoxContainer {
  justify-content: center;
  margin-top: 30px;
  gap: 50px;
}
.CF-CarouselStyles {
  width: 100%;
}
.CF-CarouselStyles .carousel {
  padding-bottom: 40px;
}
.CF-CarouselStyles .slide {
  text-align: left;
}

.CF-CarouselStyles .carousel .control-dots {
  margin: 3px 0px;
}
/* .CF-card-container {
  height: 200px;
  width: 200px;
  border: 1px solid;
} */
/* .CF-card-container {
  width: 500px;
} */
@media only screen and (min-width: 650px) and (max-width: 1200px) {
  .CF-FeaturesBoxContainer {
    margin-top: 15px;
    gap: 35px;
  }
  .CB-chatBotFeatures {
    margin: 50px auto !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 650px) {
  .CF-FeaturesBoxContainer {
    margin-top: 15px;
    gap: 35px;
  }
  .CB-chatBotFeatures {
    margin: 0px 0px 50px !important;
  }
}
