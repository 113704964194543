.ods-main-head-text {
    font-size: 38px !important;
    font-weight: 600 !important;
    color: #263238 !important;
}

.ods-main-subhead-text {
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #263238 !important;
}

.ods-main-title-text {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #263238 !important;
}

.ods-main-title-grid {
    max-width: 70% !important;
}

.ods-request-demo-btn {
    background-color: #6A097D !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 10px 25px !important;
}

.ods-card-main-item {
    /* background-color: #11092D !important; */
    background-color: #FFFFFF !important;
    padding: 50px 0px !important;
    position: relative !important;
}

.ods-card-main-item::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px !important; 
    padding: 7px; 
    background: linear-gradient(to right, #082841, #A41D21, #B33A32) !important;
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
}