.global-dark-button-style {
    background-color: rgba(106, 9, 125, 1) !important;
    text-transform: none !important;
    border-radius: 10px !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 8px 20px !important;
    cursor: pointer !important;
    box-shadow: 0px 0px 4px rgba(10, 106, 221, 1) !important;
}

.global-dark-button-gradient-style {
    background: linear-gradient(261.43deg, #6A097D 11.69%, #0A6ADD 127.48%) !important;
    text-transform: none !important;
    border-radius: 5px !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 8px 20px !important;
    box-shadow: 0px 0px 15.2px rgba(233, 102, 251, 0.15) !important;
    cursor: auto !important;
}

@media screen and (max-width: 600px) {
    .global-dark-button-style {
        font-size: 15px !important;
        padding: 7px 18px !important;
    }

    .global-dark-button-gradient-style {
        font-size: 14px !important;
        padding: 7px 18px !important;
    }
}