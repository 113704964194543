.ai-fbe-card-item {
    margin: 50px auto !important;
}

.ai-fb-head-item {
    margin: 0px auto 15px !important;

}

.ai-fb-main-head {
    font-size: 40px !important;
    font-weight: 600 !important;
    color: black !important;
    line-height: 1.2 !important;
}

.ai-fb-sub-head {
    font-size: 19px !important;
    font-weight: 500 !important;
    color: black !important;
    line-height: 1.2 !important;
}

.ai-fbe-main-item {
    max-width: 1200px !important;
    margin: 10px auto !important;
    text-align: center !important;
}

.ai-fbe-card-sub-item {
    height: 200px !important;
    max-height: 200px !important;
    text-align: center;
    padding: 25px !important;
    max-width: 320px !important;
    width: 320px !important;
    min-width: 320px !important;
}

.ai-fbe-card-head-text {
    font-size: 35px !important;
    font-weight: 500 !important;
}

.ai-fbe-card-subhead-text {
    font-size: 15px !important;
    font-weight: 500 !important;
}


@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ai-fbe-card-head-text {
        font-size: 32px !important;
        font-weight: 500 !important;
    }

    .ai-fbe-card-subhead-text {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ai-fb-main-head {
        font-size: 35px !important;
        font-weight: 600 !important;
        color: black !important;
        line-height: 1.2 !important;
    }

    .ai-fb-sub-head {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: black !important;
        line-height: 1.2 !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ai-fbe-card-head-text {
        font-size: 32px !important;
        font-weight: 500 !important;
    }

    .ai-fbe-card-subhead-text {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ai-fb-main-head {
        font-size: 32px !important;
        font-weight: 600 !important;
        color: black !important;
        line-height: 1.2 !important;
    }

    .ai-fb-sub-head {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: black !important;
        line-height: 1.2 !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
    .ai-fbe-card-head-text {
        font-size: 32px !important;
        font-weight: 500 !important;
    }

    .ai-fbe-card-subhead-text {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ai-fb-main-head {
        font-size: 32px !important;
        font-weight: 600 !important;
        color: black !important;
        line-height: 1.2 !important;
    }

    .ai-fb-sub-head {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: black !important;
        line-height: 1.2 !important;
    }
}

@media only screen and (min-width: 450px) and (max-width: 899px) {
    .ai-fbe-card-head-text {
        font-size: 32px !important;
        font-weight: 500 !important;
    }

    .ai-fbe-card-subhead-text {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ai-fb-main-head {
        font-size: 28px !important;
        font-weight: 600 !important;
        color: black !important;
        line-height: 1.2 !important;
    }

    .ai-fb-sub-head {
        font-size: 16px !important;
        font-weight: 500 !important;
        color: black !important;
        line-height: 1.2 !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 449px) {
    .ai-fbe-card-head-text {
        font-size: 30px !important;
        font-weight: 500 !important;
    }

    .ai-fbe-card-subhead-text {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ai-fb-main-head {
        font-size: 26px !important;
        font-weight: 600 !important;
        color: black !important;
        line-height: 1.2 !important;
    }

    .ai-fb-sub-head {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: black !important;
        line-height: 1.2 !important;
    }
}