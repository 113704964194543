.cw-tryit-free-btn,
.cw-tryit-wp-free-btn {
    background-color: white !important;
    color: #6A097D !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    padding: 10px 15px !important;
}


.cw-tryit-btn-text-item,
.cw-tryit-head-text-item,
.cw-tryit-head-subhead-item {
    margin: 20px auto !important;
    text-align: start !important;
}

.cw-tryit-head-text {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: white !important;
}

.cw-tryit-highlighted-head-text {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #FFB762 !important;
}

.cw-tryit-subhead-text {
    color: white !important;
    font-size: 19px !important;
    font-weight: 400 !important;
}