/* .cs-card-container {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    max-width: 390px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cs-card-container:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
} */

.cs-image-container {
    margin: 0px 0px 10px !important;
    height: 220px !important;

}

.cs-image {
    max-width: 100%;
    /* height: 100%; */
    width: 100%;
    border-radius: 5px;
    aspect-ratio: 1.5;
}

.cs-title {
    font-weight: 600;
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
}

.cs-description {
    margin: 10px 0px !important;
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    height: 80px !important;
    max-height: 80px !important;
}

.cs-card-section {
    padding: 50px;
    background-color: #f6f6f6;
    border-radius: 10px;
    margin: 20px auto;
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cs-section-title h2 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.chatbot-card-bg {
    position: relative;
    width: 100%;
    background: url("../../assests/GlobalImages/curveBackground.svg");
    background-size: cover !important;
    background-position: center !important;
    padding: 100px 0;
}

.cs-background-container {}

.cs-background-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* z-index: -1; */
}

.cs-background-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.lc-blog-explore-btn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500px !important;
    text-transform: none !important;
}

.cs-card-item {
    min-width: 350px !important;
    width: 350px !important;
    max-width: 350px !important;
    height: 420px !important;
    max-height: 420px !important;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.cs-card-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 900px) {
    .cs-card-section {
        padding: 20px;
    }

    .cs-section-title h2 {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .cs-card-item {
        min-width: 100% !important;
        width: 100% !important;
        max-width: auto !important;
        height: auto !important;
        max-height: auto !important;
    }

    .cs-card-section {
        padding: 10px !important
    }

    .cs-card-item {
        padding: 20px 10px !important;
    }

    .cs-section-title h2 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }
}