.tech-expo-main-container-style::before{
    content: "";
    background: linear-gradient(135deg, #000000 0, #000044ba 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.tech-expo-main-container-style {
    background: url('./../../assests/GlobalImages/Tech-Expo-banner.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    position: relative !important;
}

.tech-expo-sub-container-style {
    padding: 150px 0px !important;
    position: relative;
    z-index: 1;
    height: 100% !important;
}

.tech-expo-heading-style {
    font-size: 48px !important;
    font-weight: 600 !important;
    color: #F7E9FF !important;
}

.tech-expo-sub-heading-style {
    font-size: 24px !important;
    font-weight: 500 !important;
    color: white !important;
}

.tech-expo-sub-content-style {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: white !important;
}

.tech-expo-contact-us-button {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    background-color: #FFF !important;
    color: #6A097D !important;
    border: 1px solid #6A097D !important;
    padding: 8px 20px !important;
    width: 180px !important;
    margin-top: 15px !important;
    position: relative !important;
    display: inline-block !important;
    transition: all 0.3s !important;
    overflow: hidden !important;
    z-index: 0 !important;
}

.tech-expo-contact-us-button:hover {
    color: #FFF !important;
    background-color: #6A097D  !important;
}

.tech-expo-right-image-style {
    width: 100% !important;
    max-width: 750px !important;
}

/* .agentImageStyles {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInClass {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease-out, transform 1s ease-out;
} */


@media screen and (min-width: 1350px) and (max-width: 1600px) {
    .tech-expo-sub-container-style {
        padding: 150px 80px !important;
    }

    .tech-expo-right-image-style {
        width: 670px !important;
    }

    .tech-expo-heading-style {
        font-size: 40px !important;
    }
    
    .tech-expo-sub-heading-style {
        font-size: 21px !important;
    }
    
    .tech-expo-sub-content-style {
        font-size: 16px !important;
    }

    .tech-expo-contact-us-button {
        font-size: 15px !important;
        background-color: #FFF !important;
        padding: 6px 20px !important;
        width: 170px !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1350px) {
    .tech-expo-sub-container-style {
        padding: 100px 45px !important;
    }

    .tech-expo-right-image-style {
        width: 520px !important;
        box-sizing: border-box !important;
    }

    .tech-expo-heading-style {
        font-size: 30px !important;
    }
    
    .tech-expo-sub-heading-style {
        font-size: 20px !important;
    }
    
    .tech-expo-sub-content-style {
        font-size: 15px !important;
    }

    .tech-expo-contact-us-button {
        font-size: 14px !important;
        padding: 6px 20px !important;
        width: 150px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .tech-expo-sub-container-style {
        padding: 100px 45px !important;
    }

    .tech-expo-right-image-style {
        width: 85vw !important;
        box-sizing: border-box !important;
    }

    .tech-expo-heading-style {
        font-size: 30px !important;
    }
    
    .tech-expo-sub-heading-style {
        font-size: 20px !important;
    }
    
    .tech-expo-sub-content-style {
        font-size: 15px !important;
    }

    .tech-expo-contact-us-button {
        font-size: 14px !important;
        padding: 6px 20px !important;
        width: 150px !important;
    }
}


@media screen and (max-width: 600px) {
    .tech-expo-sub-container-style {
        padding: 100px 30px !important;
    }

    .tech-expo-right-image-style {
        width: 85vw !important;
        box-sizing: border-box !important;
    }

    .tech-expo-heading-style {
        font-size: 26px !important;
    }
    
    .tech-expo-sub-heading-style {
        font-size: 18px !important;
    }
    
    .tech-expo-sub-content-style {
        font-size: 14px !important;
    }

    .tech-expo-contact-us-button {
        font-size: 14px !important;
        padding: 6px 20px !important;
        width: 150px !important;
    }
}