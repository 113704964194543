.cp-main-head-sec-item {
    margin: 0 auto 10px !important;
}

.cp-main-card-sec-item {
    margin: 10px auto 30px !important;
}


.cp-faq-main-item {
    /* background: linear-gradient(180deg, rgba(247, 233, 255, 1) 44%, rgba(247, 233, 255, 1) 66%, rgba(247, 233, 255, 1) 87%, rgba(255, 255, 255, 1) 100%); */
    height: auto;
    padding-bottom: 100px !important;
    margin: 20px 0px !important;
}

@media only screen and (min-width : 0px) and (max-width : 600px) {
    .cp-main-head-sec-item {
        margin: 65px auto 10px !important;
    }
}