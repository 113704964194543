.arp-content-main-wrapper {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 19%, rgba(255, 255, 255, 1) 35%, rgba(254, 252, 255, 1) 56%, rgba(238, 201, 255, 1) 100%); */
  /* background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 1) 50%, rgba(254, 252, 255, 1) 68%, rgba(255, 235, 201, 1) 100%); */

  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(254, 252, 255, 1) 80%, rgba(255, 235, 201, 1) 100%);

  /* background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 1) 50%, rgba(254, 252, 255, 1) 75%, rgba(238, 201, 255, 1) 100%); */
}

.arp-content-main-item {
  margin: 80px auto 0px !important;
}

.arp-content-main-container {
  /* justify-content: space-between; */
  gap: 20px;
}

.arp-content-list-item {
  /* border-right: 1px solid #E0E0E0 !important; */
}

.arp-divider-item {
  display: flex;

}

.arp-divider {
  color: 2px solid #E0E0E0 !important;
}



.arp-blog-content-head {
  color: black !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.arp-blog-content-head-item {
  margin: 10px 0px !important;
}

.artical-bullet-points {
  font-size: 10px !important;
  color: black !important;
  width: 50px !important;
}

.artical-bullet-points-selected {
  color: #6A097D !important;
  width: 50px !important;
  font-size: 10px !important;
}

.arrow-up-down {
  color: black !important;
}

.arrow-up-down-selected {
  color: #6A097D !important;
}

.arp-sub-heading-selected,
.arp-main-heading-selected {
  color: #6A097D !important;
  border-left: 3px solid #6A097D !important;
}

.arp-sub-heading,
.arp-main-heading {
  color: black !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

/* Inside your ArticlePage.css */
.arp-content-main-container {
  position: relative;
}

.arp-nav-item {
  overflow-y: auto !important;
  height: 100% !important;
  min-height: 100% !important;
  position: sticky !important;
  top: 110px !important;
  margin: 0 auto !important;
}

.arp-blog-content-item {
  overflow-y: auto !important;
  min-height: 100% !important;
  position: sticky !important;
  top: 150px !important;
}

.arp-list-text span {
  font-size: 15px !important;
  font-weight: 500 !important;
}


.arp-blog-head-title {
  font-size: 40px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.arp-blog-head-title-item {
  margin: 0px auto 20px !important;
}


.artical-paragraph-global-text {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.artical-paragraph-global-text-list {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.article-navigation-link {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #6A097D !important;
}

.article-navigation-link:hover {
  text-decoration: underline !important;
}

.arp-paragraph-global-head-text {
  font-size: 25px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.arp-paragraph-global-sub-head-text {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #000
}

.arp-blog-head-wrapper {
  margin: 0px 0px 80px !important;
}

.arp-blog-content-spacing {
  margin: 10px 0px !important;
}

.arp-paragraph-global-head-text-item {
  margin: 0px 0px 30px !important;
}

.arp-paragraph-global-text-item {
  margin: 0px 0px 10px !important;
}

.arp-paragraph-global-sub-head-text-item {
  margin: 40px 0px 40px !important;
}

.arp-mobile-dropdown {
  position: sticky;
  top: 100px;
  background-color: white;
  z-index: 1;
}