.slack-side-by-side-hero-section {
    padding: 90px auto 0 !important;
    width: 100%;
    min-height: 700px;
}

.slack-client-logo-section {
    margin: 20px auto 20px !important;
    width: 100%;
}

.slack-image-banner {
    margin: 50px auto 0 !important;
    /* background-color: #6A097D; */
    padding: 80px 0px !important;
}

.slack-pr-overlap-item {
    margin: 150px auto 0 !important;
    position: relative;
}

.slack-hero-section-btn-style {
    background-color: #6A097D !important;
    color: white;
    height: 50px;
    width: 200px;
    border-radius: 10px !important;
    text-transform: none !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.slack-hero-section-heading-style {
    font-size: 43px !important;
    font-weight: 700 !important;
    color: #000000 !important;
    line-height: 1.25 !important;
}

.slack-hero-section-description-style {
    font-size: 19px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

/* Media Queries for medium screens (between 900px and 1200px) */
@media only screen and (max-width: 1200px) {
    .slack-hero-section-heading-style {
        font-size: 36px !important;
        font-weight: 600 !important;
    }

    .slack-hero-section-description-style {
        font-size: 18px !important;
        font-weight: 500 !important;
    }
}

/* Media Queries for small screens (between 600px and 900px) */
@media only screen and (max-width: 900px) {
    .slack-hero-section-heading-style {
        font-size: 32px !important;
        font-weight: 600 !important;
    }

    .slack-hero-section-description-style {
        font-size: 16px !important;
        font-weight: 500 !important;
    }
}

/* Media Queries for extra small screens (below 600px) */
@media only screen and (max-width: 600px) {
    .slack-hero-section-heading-style {
        font-size: 28px !important;
        font-weight: 600 !important;
    }

    .slack-hero-section-description-style {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .slack-side-by-side-hero-section {
        padding: 60px auto 0 !important;
    }
}