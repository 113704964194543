.lcf-card-frame-item {
    padding: 50px 50px 0px 50px !important;
    /* max-width: 500px !important; */
}

.lcf-card-main-item {
    /* max-width: 1200px !important; */
    margin: 0 auto !important;
}

.lcf-card-subhead {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.lcf-card-head {
    font-size: 30px !important;
    font-weight: 600 !important;
}

.lcf-card-head-item {
    margin: 0px auto 10px !important;
    text-align: center;
    /* height: 85px !important; */
    display: flex !important;
    align-items: start;
    justify-content: center;
}

.lcf-card-frame-container {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    gap: 50px;
    height: 100% !important;
}

.lcf-card-subhead-item {
    margin: 5px auto 0px !important;
    text-align: center;
    /* height: 150px !important; */
    display: flex !important;
    align-items: start;
    justify-content: center;
}

.lcf-head-item {
    margin: 50px auto !important;
    text-align: center;
}

.lcf-head-item h2 {
    font-size: 35px !important;
    font-weight: 600 !important;
}

.landing-image-chatbot-item {
    text-align: center !important;
    max-height: 500px !important;
    height: 500px !important;
}

.landing-image-chatbot {
    /* width: 500px; */
    /* max-width: 500px; */

    width: 100%;
    height: 100%;
    /* max-height: 500px !important; */
    margin: 0 auto -59px !important;
    text-align: center !important;
    border-radius: 15px 15px 0px 0px !important;

    /* width: 100%;
    height: 500px;
    max-height: 500px !important;
    margin: 0 auto -57px !important;
    text-align: center !important;
    border-radius: 15px 15px 0px 0px !important; */
}

.landing-image-chatbot-iframe {
    height: 500px !important;
    min-height: 500px !important;
    width: 100% !important;
    border-radius: 15px 15px 0px 0px !important;
}

@media only screen and (min-width: 1200px) {

    .lcf-head-item {
        margin: 20px auto 40px !important;
        text-align: center;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .lcf-card-subhead {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .lcf-card-head {
        font-size: 25px !important;
        font-weight: 600 !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 965px) {
    .lcf-card-frame-item {
        padding: 50px 30px !important;
    }

    .landing-image-chatbot {
        width: 100%;
        height: auto;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
    .lcf-card-subhead {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .lcf-card-head {
        font-size: 25px !important;
        font-weight: 600 !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .lcf-head-item h2 {
        font-size: 28px !important;
        font-weight: 500 !important;
        color: black !important;
    }

    .lcf-card-frame-item {
        padding: 30px 15px !important;
    }

    .lcf-head-item {
        margin: 50px auto 30px !important;
        text-align: center;
    }

    .lcf-head-item h2 {
        font-size: 25px !important;
        font-weight: 500 !important;
    }

    .landing-image-chatbot {
        width: 100%;
        height: 100%;
        margin: 0 auto 0px !important;
        text-align: center !important;
    }

    .lcf-card-frame-item {
        padding: 30px 15px 0px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 380px) {
    .lcf-card-head {
        font-size: 20px !important;
        font-weight: 600 !important;
    }
    
    .lcf-card-subhead {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .landing-image-chatbot {
        margin: 0px auto -30px !important;
    }


}