.brws-ext-feature-head-main-item {
    margin: 0 auto !important;
}

.brws-ext-feature-head-item {
    text-align: center !important;
    margin: 10px 0px !important;
}

/* 
.brws-ext-feature-head-item span {
    font-size: 38px !important;
    font-weight: 600 !important;
    border-bottom: 3px solid rgba(106, 9, 125, 0.8);
    border-radius: 10px 10px 0 0;
} */

.brws-ext-feature-head-item span span {
    color: #6a097d !important;
}

.brws-ext-feature-head-subtitle-item {
    text-align: center;
    margin: 10px auto !important;
}

.brws-ext-feature-head-subtitle-item span {
    font-size: 20px;
    font-weight: 600;
    color: #7a7a7a;
}

.brws-ext-feature-main-item {
    margin-inline: auto !important;
}

.brws-ext-feature-sub-container {
    justify-content: space-between;
    align-items: center;
    margin: 150px auto !important;
    /* width: 90% !important; */
}

.brws-ext-feature-sub-container:nth-of-type(odd) {
    flex-direction: row-reverse !important;
}

/* .brws-ext-feature-sub-container:nth-last-of-type(odd) {
    width: 92% !important;
} */

.brws-ext-feature-sub-container:nth-of-type(even) {
    justify-content: center;
}

@media (max-width: 900px) {
    /* .brws-ext-feature-sub-container:nth-of-type(odd) {
        width: 100% !important;
    } */

    .brws-ext-feature-sub-right-section-even {
        justify-content: center !important;
    }
}

.brws-ext-features-heading-item h3 {
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-bottom: 10px;
}

.brws-ext-features-heading-item h3 span {
    color: #6a097d !important;
}

.brws-ext-features-desc-list-item {
    margin: 0px 0px 20px !important;
}

.brws-feature-dt-list {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: black !important;
    margin: 10px 0px !important;
}

.brws-ext-features-desc-list-item dd {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: black !important;
    margin-left: 0px !important;
}

.brws-ext-feature-sub-right-section {
    border-radius: 20px !important;
}

.brws-ext-feature-sub-right-section img {
    border-radius: 15px !important;
}

.brws-ext-feature-sub-right-img {
    width: 600px !important;
    max-width: 100%;
    height: fit-content;
    object-fit: contain !important;
    box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.25) !important;
}

@media only screen and (min-width: 320px) and (max-width: 599px) {
    .brws-ext-feature-head-item span {
        font-size: 16px !important;
        font-weight: 600 !important;
        border-bottom: 2.5px solid rgba(106, 9, 125, 0.8);
    }

    .brws-ext-feature-head-subtitle-item span {
        font-size: 13px;
    }

    .brws-ext-feature-sub-right-section {
        /* background-color: #fbf4ff !important; */
        border-radius: 20px !important;
    }

    .brws-ext-feature-sub-container {
        margin: 40px auto !important;
    }

    .brws-ext-features-heading-item h3 {
        font-size: 15px !important;
    }

    .brws-feature-dt-list {
        font-size: 13px !important;
    }

    .brws-ext-features-desc-list-item dd {
        font-size: 13px !important;
    }

    .brws-ext-feature-sub-right-img {
        width: 300px !important;
    }

    .brws-ext-feature-sub-right-section img {
        border-radius: 15px !important;
        max-height: 350px !important;
        height: 350px !important;
        object-fit: contain !important;
    }
}