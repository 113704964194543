.pr-ai-contact-label-item {
    font-size: 17px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    color: #000 !important;
}

.pr-overlap-head-item span {
    font-weight: 600 !important;
}
.pr-ai-contact-label-item span {
    font-weight: 600 !important;
}