.cc-chatbot-dots {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    list-style-type: none !important;
    padding: 0 !important;
}


.cc-chatbot-dots li button:before {
    background-color: #6A097D !important;
    color: #6A097D !important;
    width: 100% !important;
    border-radius: 5px;
    padding: 1px !important;
    content: '' !important;
    height: 6px !important;
}

.cc-slick-dots .cc-chatbot-dots li button {
    height: 6px !important;
}

.cc-chatbot-dots li {
    width: 50px !important;
    height: auto !important;
    padding: 1px !important;
}

.cc-chatbot-dots li:hover {
    background-color: transparent !important;
    color: transparent !important;
}

.cc-dot-container {
    text-align: center !important;
    padding: 10px !important;
    border-radius: 10px !important;
    background-color: #fff !important;
    /* Background of the dot container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    /* Optional: adds shadow to the dot container */
}

.cc-chatbot-click-slider .slick-dots {
    bottom: 0px !important;
    position: relative !important;
}

.cc-cw-slider-bg-img {
    max-width: 100%;
    min-height: 400px;
    width: 95%;
    margin: auto;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
}

.cc-slider-whatsapp-img {
    max-width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
}