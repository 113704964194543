.voice-ai-global-page-item {
    background: linear-gradient(45deg, #F7E9FF, #FDFFEA) !important;
}

.voice-ai-global-page-container {
    height: 100vh !important;
    background: url('./../../assests/Pages/VoiceAI/voice-ai-bg.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.voice-ai-text-style {
    color: rgba(76, 74, 77, 1) !important;
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 42px !important;
}

.voice-ai-heading-style {
    font-size: 80px !important;
    font-weight: 700 !important;
    color: #6A097D !important;
}

.voice-ai-image-button-container {
    transform: translate(125px, 60px) !important;
}

.voice-ai-image-animation-style {
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeLogoIn 1s ease-out forwards;
}

@keyframes fadeLogoIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-style {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1s ease-out, transform 1s ease-out !important;
}

.coming-soon-button-container {
    opacity: 0;
    transform: translateX(10px);
    animation: fadeButtonIn 1s ease-out forwards;
}

@keyframes fadeButtonIn {
    from {
        opacity: 0;
        transform: translateX(10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.textWrtingAnimationStyle {
    display: inline-block;
}

.typingEffect {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    border-right: 2px solid rgba(106, 9, 125, 1);
    animation: typing 3.5s linear forwards, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
    from { width: 0; }
    to { width: 100%;}
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
        border-right: none;
    }
    50% {
        border-color: rgba(106, 9, 125, 1);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .voice-ai-text-style {
        font-size: 30px !important;
        line-height: 40px !important;
    }

    .voice-ai-image-button-container {
        transform: translate(110px, 60px) !important;
    }

    .voice-ai-heading-style {
        font-size: 74px !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .voice-ai-text-style {
        font-size: 26px !important;
        line-height: 36px !important;
    }

    .voice-ai-image-button-container {
        transform: translate(100px, 60px) !important;
    }

    .voice-ai-heading-style {
        font-size: 64px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .voice-ai-text-style {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    .voice-ai-heading-style {
        font-size: 56px !important;
    }

    .voice-ai-image-button-container {
        transform: translate(100px, 50px) !important;
    }
}

@media screen and (max-width: 600px) {
    .voice-ai-text-style {
        font-size: 18px !important;
        line-height: 28px !important;
    }

    .voice-ai-heading-style {
        font-size: 46px !important;
    }

    .voice-ai-image-button-container {
        transform: translate(0, 30px) !important;
    }

    .voice-ai-image {
        width: 140px !important;
    }
}