.front-main-section-container {
    /* background: radial-gradient(circle, rgba(192,242,232,1) 0%, rgba(198,228,255,1) 50%, rgba(255,255,255,1) 100%); */
    background: url('./../../assests/LandingPage/newBgGradient.svg');
    /* background-position: center bottom !important; */
    background-Position: center bottom !important;
    background-repeat: no-repeat !important;
    /* animation: bg 15s ease-in infinite; */
}

/* @keyframes bg {
    0% {
        background: radial-gradient(circle, rgba(192,242,232,1) 0%, rgba(198,228,255,1) 50%, rgba(255,255,255,1) 100%);
        background-size: 30% 220% !important;
        background-position: center right !important;
    }
    50% {
        background: radial-gradient(circle, rgba(209,255,228,1) 0%, rgba(205,241,245,1) 50%, rgba(255,255,255,1) 100%);
        background-size: 40% 250% !important;
        background-position: center top !important;
    }
    100% {
        background: radial-gradient(circle, rgba(172,242,232,1) 0%, rgba(198,228,255,1) 50%, rgba(255,255,255,1) 100%);
        background-size: 30% 220% !important;
        background-position: center bottom !important;
    }
} */

.front-head-text-main-item {
    margin: 150px auto 0px !important;
}

.front-head-text-item {
    text-align: end !important;
    margin: 20px auto !important;
}

.front-head-normal-text-color {
    font-size: 46px !important;
    line-height: 1.3 !important;
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.9) 70%, rgba(148, 148, 148, 1) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-weight: 700 !important;
}

.front-head-normal-sub-text-color {
    font-size: 36px !important;
    line-height: 1.3 !important;
    font-weight: 600 !important;
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.9) 70%, rgba(148, 148, 148, 1) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.front-head-sub-normal-text-color {
    font-size: 21px !important;
    font-weight: 600 !important;
    line-height: 1.05 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.front-head-btn-item {
    text-align: center !important;
    margin: 0px auto !important;
}

.front-head-free-btn {
    background-color: #0A6ADD !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    padding: 9px 40px !important;
    max-width: 350px !important;
    /* border: 1px solid #124CEA !important; */
}

.front-head-free-new-btn {
    background-color: transparent !important;
    color: #0A6ADD !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    padding: 8px 40px !important;
    max-width: 350px !important;
    border: 1px solid #0A6ADD !important;
}

/* Add this to your main CSS file */
.chatbot-dots {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    list-style-type: none !important;
    padding: 0 !important;
    margin-top: 35px !important;
}


.chatbot-dots li button:before {
    background-color: #6A097D !important;
    color: #6A097D !important;
    width: 100% !important;
    border-radius: 5px;
    padding: 1px !important;
    content: '' !important;
    height: 6px !important;
}

.slick-dots .chatbot-dots li button {
    height: 6px !important;
}

.chatbot-dots li {
    width: 50px !important;
    height: auto !important;
    padding: 1px !important;
}

.chatbot-dots li:hover {
    background-color: transparent !important;
    color: transparent !important;
}


.chatbot-click-front-slider .slick-list {
    overflow: visible !important;
}

.chatbot-click-front-slider .slick-next:before {
    color: rgba(106, 9, 125, 0.6) !important;
    font-size: 22px !important;
}

.chatbot-click-front-slider .slick-next {
    position: absolute !important;
    right: 0 !important;
    left: 60px !important;
    top: 105% !important;
    fill: rgba(106, 9, 125, 1) !important;
    background-color: rgba(247, 233, 255, 1) !important;
    border-radius: 50% !important;
    padding: 5px !important;
}

.chatbot-click-front-slider .slick-prev {
    position: absolute !important;
    left: 5px !important;
    top: 105% !important;
    fill: rgba(106, 9, 125, 1) !important;
    background-color: rgba(247, 233, 255, 1) !important;
    border-radius: 50% !important;
    padding: 5px !important;
}

.chatbot-click-front-slider .MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
}

.chatbot-click-front-slider .slick-prev:before {
    color: rgba(106, 9, 125, 0.6) !important;
    font-size: 22px !important;
}

.slide {
    height: 100% !important;
}

.carousel-image-position-style {
    position: absolute !important;
    z-index: 3 !important;
    top: 40px !important;
    right: 30px !important;
}

.carousel-content-component {
    width: 100% !important;
    position: relative;
    padding: 150px 80px !important;
    background-color: transparent !important;
    overflow: hidden !important;
    border-radius: 15px !important;
}


.carousel-content-component img{
    width: 100% !important;
    height: 100% !important;
}

.carousel-content-component::before {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center !important;
    filter: blur(2px) !important;
    /* background-color: rgba(0, 0, 0, 0.6) !important; */
    z-index: 1 !important;
}

.carousel-content-component > * {
    position: relative !important;
    z-index: 2 !important;
}

.carousel-heading-style, 
.carousel-subheading-style, 
.carousel-button-style {
    z-index: 2 !important;
    color: white !important;
}

.carousel-heading-style {
    font-size: 32px !important;
    color: white !important;
    font-weight: 700 !important;
}

.carousel-subheading-style {
    font-size: 18px !important;
    color: white !important;
    font-weight: 600 !important;
}

.carousel-button-style {
    background-color: rgba(10, 106, 221, 1) !important;
    border-radius: 12px !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    box-shadow: 0px 0px 4px rgba(10, 106, 221, 1) !important;
    text-transform: none !important;
    color: white !important;
    padding: 8px 35px !important;
}

.dot-container {
    text-align: center !important;
    padding: 10px !important;
    border-radius: 10px !important;
    background-color: #fff !important;
    /* Background of the dot container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    /* Optional: adds shadow to the dot container */
}

.chatbot-click-slider .slick-dots {
    bottom: 0px !important;
    position: relative !important;
}

.hash-class-styles {
    font-size: 70px !important;
    font-weight: 700 !important;
    /* color: rgba(255, 183, 98, 0.3) !important; */
    background: linear-gradient(to bottom right, #444444 70%, rgba(231, 233, 255, 1) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.verified-text-style {
    font-size: 25px !important;
    font-weight: 700 !important;
    color: #3F3F3F !important;
    line-height: 1.05 !important;
}

.provider-text-style {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: #3F3F3F !important;
    line-height: 1.05 !important;
}

.meta-provider-image-style {
    height: 45px !important;
}

.carousel-image-position-style {
    height: 100% !important;
}

.carousel-image-position-style img{
    height: 100% !important;
    max-height: 100% !important;
}

@media screen and (max-width: 900px) {
    .front-head-normal-text-color {
        font-size: 46px !important;
    }

    .front-head-normal-sub-text-color {
        font-size: 40px !important;
    }

    .front-head-free-btn {
        font-size: 15px !important;
        font-weight: 600 !important;
    }

    .front-head-free-new-btn {
        font-size: 15px !important;
        font-weight: 600 !important;
    }

    .hash-class-styles {
        font-size: 100px !important;
    }

    .verified-text-style {
        font-size: 30px !important;
        font-weight: 700 !important;
    }

    .provider-text-style {
        font-size: 35px !important;
        font-weight: 700 !important;
    }

    .meta-provider-image-style {
        height: 80px !important;
    }

}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .front-head-normal-text-color {
        font-size: 30px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .front-head-normal-sub-text-color {
        font-size: 24px !important;
        line-height: 1.3 !important;
        font-weight: 600 !important;
    }

    .front-head-sub-normal-text-color {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 1.05 !important;
    }

    .front-head-text-main-item {
        margin: 130px auto 0px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .front-main-section-container {
        background-size: 250vw !important;
    }
}

@media screen and (min-width: 1600px) {
    .front-main-section-container {
        background-size: 220vw !important;
    }
}

@media screen and (min-width: 1800px) {
    .front-main-section-container {
        background-size: 200vw !important;
    }
}

@media screen and (max-width: 900px) {
    .carousel-content-component::before {
        height: 60% !important;
    }
    .carousel-image-position-style {
        position: relative !important;
        z-index: 3 !important;
        transform: translate(30px, -40px) !important;
    }
    .carousel-content-component {
        padding: 50px 25px !important;
        background-color: transparent !important;
        overflow: hidden !important;
        border-radius: 15px !important;
    }
    .slide {
        height: auto !important;
    }
    .carousel-image-position-style {
        height: 100% !important;
    }    
    .carousel-image-position-style img{
        height: 500px !important;
        max-height: 500px !important;
    }
    .chatbot-click-front-slider .slick-next {
        top: 30% !important;
        left: auto !important;
        right: -15px !important;
    } 
    .chatbot-click-front-slider .slick-prev {
        top: 30% !important;
        z-index: 1 !important;
        left: -15px !important;
    }
    .carousel-heading-style {
        font-size: 30px !important;
    }
    .carousel-subheading-style {
        font-size: 16px !important;
    }
    .carousel-button-style {
        border-radius: 5px !important;
        font-size: 15px !important;
        font-weight: 600 !important;
        padding: 6px 25px !important;
    }
    .chatbot-click-front-slider .slick-next:before {
        font-size: 16px !important;
    }
    .chatbot-click-front-slider .MuiSvgIcon-root {
        width: 1.2em !important;
        height: 1.2em !important;
    }   
    .chatbot-click-front-slider .slick-prev:before {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {

    .front-head-normal-text-color {
        font-size: 34px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .front-head-normal-sub-text-color {
        font-size: 28px !important;
        line-height: 1.3 !important;
        font-weight: 600 !important;
    }

    .front-head-sub-normal-text-color {
        font-size: 18px !important;
        font-weight: 600 !important;
        line-height: 1.05 !important;
    }

    .front-head-text-main-item {
        margin: 130px auto 0px !important;
    }
}