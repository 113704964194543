.fullwidth {
    width: 100% !important;
}

.phoneInputWrapper {
    /* height: 52px; */
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px;
}

.phoneInputWrapper .react-tel-input {
    height: 100%;
    display: flex !important;
    flex-direction: row-reverse !important;
    flex-wrap: nowrap !important;
}

.phoneInputWrapper .selected-flag {
    /* padding: 0 0 0 15px !important; */
    border-radius: 3px !important;
    position: relative !important;
}

.phoneInputWrapper .flag-dropdown {
    border: none !important;
    position: relative !important;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.25) !important;
}

.phoneInputWrapper .form-control {
    height: 100% !important;
}

.phoneInputWrapper .special-label {
    display: none !important;
}

.phoneInputWrapper .form-control {
    padding: 9px 14px !important;
    background-color: transparent !important;
    border: none !important;
    width: 100% !important;
    outline: none !important;
}