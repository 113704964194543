/**************************************************************************** 
GLOBAL CSS FOR FACEBOOK CHATBOT COMPONENTS
*****************************************************************************/
.outer-wrapper {
    max-width: 1320px;
    margin: 220px auto !important;
}

.m-bottom-top {
    margin: 50px auto;
}

.main-heading {
    font-size: 52px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
}

.main-sub-heading {
    font-size: 40px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
}

.highlighted-text {
    /* background: linear-gradient(79.1deg, #414DE1 10.83%, #6A097D 101.67%);
   */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
    color: #6A097D;
}

.para-content {
    font-weight: 500 !important;
    font-size: 21px !important;
    line-height: 36px;
    color: #434556 !important;
    margin-top: 30px !important;
}

.sub-para-content {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 36px;
    color: #434556 !important;
    margin-top: 30px !important;
}

@media screen and (max-width: 1440px) and (min-width: 960px) {
    .outer-wrapper {
        max-width: 1200px;
        width: 90% !important;
    }

    .main-heading {
        font-size: 40px !important;
    }

    .main-sub-heading {
        font-size: 32px !important;
    }

    .para-content {
        font-size: 18px !important;
    }

    .sub-para-content {
        font-size: 16px !important;
    }
}


@media screen and (max-width: 960px) and (min-width: 600px) {
    .outer-wrapper {
        max-width: 1200px;
        width: 90% !important;
    }

    .main-heading {
        font-size: 40px !important;
    }

    .main-sub-heading {
        font-size: 28px !important;
    }

    .para-content {
        font-size: 18px !important;
    }

}


@media screen and (max-width: 600px) and (min-width: 0px) {
    .outer-wrapper {
        max-width: 1200px;
        width: 90% !important;
    }

    .para-content {
        font-size: 18px !important;
    }

    .main-heading {
        font-size: 30px !important;
    }

    .main-sub-heading {
        font-size: 25px !important;
    }

    .para-content {
        font-size: 16px !important;
        margin-top: 15px !important;

    }

    .sub-para-content {
        font-size: 15px !important;
        margin-top: 10px !important;
    }
}


/**********************************************************************************************
FacebookIntroSection CSS
***********************************************************************************************/
.fbis-main-container {
    height: calc(100vh - 64px);
    align-items: center;
    background: linear-gradient(#e6cef3, #ffffff);
    position: relative;
}

.fbis-main-container::before {
    background-image: url("../../assests/FacebookChatbot/dots.png");
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    display: block;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.fbis-left-content-box,
.fbis-right-content-box {
    width: 50%;
}

.fbis-left-content-box {
    padding: 0px 55px 0px 0px;
}

.fbis-right-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fbis-schedule-demo-btn {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 14px 25px;
    border: 2px solid #6A097D;
    color: #6A097D;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    min-width: 160px;
    letter-spacing: 1px;
    position: relative;
    transition: 0.5s color ease-out, 0.5s border ease-out;
    transform-origin: top;
    overflow: hidden;
    z-index: 1;
}

.fbis-schedule-demo-btn::before {
    background-color: #6A097D;
    border: 2px solid #6A097D;
    content: '';
    display: block;
    height: 250%;
    width: 100%;
    border-radius: 67px;
    position: absolute;
    top: 50%;
    left: -97%;
    transform: translateY(-50%);
    transform-origin: top left;
    transition: all 0.6s;
    z-index: -1;
}

.fbis-schedule-demo-btn:hover::before {
    left: 0;
    height: 100%;
    border-radius: 0px;
}

.fbis-schedule-demo-btn:hover {
    border: 2px solid #6A097D;
    color: #fff;
}

.fbis-intro-img {
    width: 100%;
    margin: auto;
    display: block;
    z-index: 1;
}

@media screen and (max-width: 1440px) and (min-width: 960px) {

    .fbis-schedule-demo-btn {
        font-size: 15px;
    }
}

@media screen and (max-width: 960px) and (min-width: 0px) {
    .fbis-main-container {
        flex-direction: column;
    }

    .fbis-left-content-box {
        padding: 0px;
        width: 100%;
        text-align: center;
    }

    .fbis-right-content-box {
        width: 100%;
        max-height: 500px;
    }

    .fbis-intro-img {
        max-width: 475px;
        max-height: 100%;
        margin-top: 45px;
    }

    .fbis-main-heading {
        font-size: 25px !important;
    }

    .fbis-schedule-demo-btn {
        font-size: 15px;
    }
}

/**********************************************************************************************
FacebookBotFeature CSS
***********************************************************************************************/

.fbf-title-container {
    text-align: center;
    max-width: 850px;
    margin: auto;
}

.fbf-features-container {
    margin: 40px auto;
    padding: 40px 0px;
}

.fbf-right-content-box,
.fbf-left-content-box {
    width: 50%;
}

.fbf-right-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.padding-left {
    padding-left: 70px;
}

.padding-right {
    padding-right: 70px;
}

.row-reverse {
    flex-direction: row-reverse !important;
}

.fbf-feature-grid-item {
    max-width: 150px;
    margin-right: 25px !important;
}

.fbf-main-heading {
    font-size: 32px !important;
    font-weight: 600 !important;
}

.fbf-features-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0 0 0.30rem rgba(189, 189, 189, 0.5));
    border-radius: 8px;
}

.fbf-points-icon {
    max-width: 45px;
    height: auto;
}

.fbf-point-text {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 36px;
    color: #434556 !important;
    margin-top: 10px !important;
}

.outer-wrapper-without-banner {
    max-width: 1320px !important;
    margin: 120px auto !important;
}

@media screen and (max-width: 1440px) and (min-width: 960px) {
    .fbf-features-img {
        min-width: 450px;
    }

    .fbf-title-container {
        max-width: 700px;
    }

    .fbf-points-icon {
        max-width: 35px;
        height: auto;
    }

    .fbf-feature-grid-item {
        max-width: 120px;
    }

    .fbf-point-text {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 960px) and (min-width: 600px) {

    .fbf-feature-grid-item {
        max-width: 150px;
    }

    .fbf-point-text {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 600px) and (min-width: 0px) {

    .fbf-feature-grid-item {
        max-width: 100px;
        margin-right: 15px !important;
    }

    .fbf-main-heading {
        font-size: 25px !important;
    }

    .fbf-point-text {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 960px) and (min-width: 0px) {
    .fbf-features-container {
        flex-direction: column !important;
        row-gap: 25px;
    }

    .fbf-right-content-box,
    .fbf-left-content-box {
        width: 100%;
        padding: 0px;
    }

    .fbf-features-img {
        max-width: 650px;
    }

    .fbf-points-icon {
        width: 38px;
    }



}

@media screen and (max-width: 900px) and (min-width: 0px) {
    .outer-wrapper {
        max-width: 1320px;
        margin: 300px auto !important;
    }

    .outer-wrapper-without-banner {
        max-width: 1320px !important;
        margin: 120px auto !important;
    }
}