.cp-head-mian-item {
    background-image: url('../../assests//ChatbotPricing/chatbot-pricing-head-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 50px 0px;
    height: 100%;
}


.cp-head-text {
    font-size: 35px;
    font-weight: 500;
    color: white;
}

.cp-head-text-item {
    margin: 20px auto !important;
    text-align: center;
}

.cp-subhead-text-item {
    margin: 10px auto !important;
    text-align: center;
}

.cp-subhead-text {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.cp-checmark-text {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 0px 10px;
}

@media only screen and (min-width : 0px) and (max-width : 600px) {
    .cp-head-text-item {
        margin: 20px auto !important;
        text-align: start;
    }

    .cp-subhead-text-item {
        text-align: start;
    }

    .cp-head-mian-item {
        padding: 30px 0px !important;
        margin: 10px auto !important;
    }

    .cp-checkmarks-item {
        width: 100%;
    }
}