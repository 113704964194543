/* .fbc-main-container {
    padding: 150px 0px !important;
}

.fbc-main-container-without-banner {
    padding: 100px 0px !important;
} */

.facebool-chatbot-faq-section {
    background: linear-gradient(180deg,
            rgba(247, 233, 255, 1) 44%,
            rgba(247, 233, 255, 1) 66%,
            rgba(247, 233, 255, 1) 87%,
            rgba(255, 255, 255, 1) 100%);
    height: auto;
    padding-bottom: 100px !important;
    margin: 60px 0px 120px !important;
}

.faceboor-pr-overlap-item {
    position: relative;
}