.gi-card-attendee-item {}

.gi-card-main-item {
    margin: 50px auto !important;
}

.gai-ticket-img {
    width: 300px;
    height: auto;
}


.gradient-button {
    background: linear-gradient(90deg, #114A65 0%, #C03B32 100%) !important;
    color: #fff !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    font-size: 22px !important;
    font-weight: bold !important;
    text-transform: none !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: all 0.3s ease !important;
    border: none !important;
}

.gradient-button:hover {
    background: linear-gradient(90deg, #0e3d53 0%, #a4312b 100%) !important;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
}