.cci-main-item-1 {
    background-image: url("../../assests/chatbot-cards-frame.png");
    /* Specify the path to your image */
    background-size: cover;
    background-position: center;
    min-width: 100%;
    padding: 30px 0px 30px;
    margin: 0px auto 80px !important;
}

.cci-items-container {
    align-items: center;
    justify-content: center;
}

.cci-main-heading {
    font-size: 40px !important;
    font-weight: 500 !important;
    border-bottom: 3px solid #6a097dcc !important;
    color: #1f384c !important;
}

.cci-card-item {
    max-width: 381px;
    width: 381px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background-color: #ffff;
    padding: 16px 20px;
    opacity: 0;
}
.animate .cci-card-item {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: calc(0.12s + var(--delay) * 0.12s);
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
.cci-cards-img {
    width: 35px;
    object-fit: fill;
    height: auto;
}

.cci-cards-container {
    gap: 45px;
    justify-content: center;
}

.cci-cards-item-container {
    justify-content: space-between;
}

.cci-cards-heading {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #6a097d !important;
}

.cci-cards-content {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #1f384c !important;
}

.cci-main-item-2 {
    /* padding: 100px 0px 120px; */
    /* margin-top: 50px !important; */
    padding: 30px 0px 30px;
    margin: 50px 0px 50px !important;
}

.cci-item-2-cards-container {
    justify-content: space-between;
    row-gap: 50px;
}

.cci-main-item-2-heading {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #1f384c !important;
    border-bottom: 3px solid #6a097dcc;
}

.cci-item-2-card-container {
    width: 100%;
    height: 100% !important;
    max-width: 700px;
    padding: 30px;
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px #00000040;
    margin: auto;
}

.cci-item-2cards-img {
    width: 100%;
    max-width: 620px;
    height: auto;
    display: flex;
    margin: auto;
}
.cci-chatbase-cards-item {
    margin-top: 35px !important;
}
.cci-main-cards-item {
    margin-top: 40px !important;
}
.cci-subheading-typo {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #1f384c !important;
}
.cci-subheading-item {
    margin: 20px 0px 15px !important;
    text-align: center;
}
.cci-cards-content-item {
    margin-top: 10px !important;
}

@media only screen and (min-width: 1900px) {
    .cci-main-heading,
    .cci-main-item-2-heading {
        font-size: 40px !important;
    }
    .cci-subheading-typo {
        font-size: 21px !important;
    }
    .cci-cards-heading {
        font-size: 20px !important;
    }
    .cci-cards-content {
        font-size: 16px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
    .cci-main-heading,
    .cci-main-item-2-heading {
        font-size: 36px !important;
    }
    .cci-subheading-typo {
        font-size: 20px !important;
    }
    .cci-cards-heading {
        font-size: 20px !important;
    }
    .cci-cards-content {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .cci-main-heading,
    .cci-main-item-2-heading {
        font-size: 30px !important;
        width: fit-content !important;
        border-bottom: 3px solid #6a097dcc !important;
    }
    .cci-subheading-typo {
        font-size: 18px !important;
    }
    .cci-cards-heading {
        font-size: 18px !important;
    }
    .cci-cards-content {
        font-size: 15px !important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
    .cci-main-heading,
    .cci-main-item-2-heading {
        font-size: 30px !important;
        width: fit-content !important;
        border-bottom: 3px solid #6a097dcc !important;
    }
    .cci-subheading-typo {
        font-size: 17px !important;
    }
    .cci-cards-heading {
        font-size: 18px !important;
    }
    .cci-cards-content {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cci-main-heading {
        font-size: 25px !important;
        width: fit-content !important;
        border-bottom: 3px solid #6a097dcc !important;
    }
    .cci-main-item-2-heading {
        font-size: 25px !important;
    }
    .cci-subheading-typo {
        font-size: 18px !important;
    }
    .cci-cards-heading {
        font-size: 17px !important;
    }
    .cci-cards-content {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .cci-cards-container {
        gap: 25px;
    }
    .cci-chatbase-cards-item {
        margin-top: 20px !important;
    }
    .cci-subheading-item {
        margin: 8px 0px 25px !important;
        text-align: center;
    }
    .cci-main-cards-item {
        margin-top: 25px !important;
    }
    .cci-card-item {
        max-width: 300px !important;
        width: 300px !important;
    }
    .cci-main-item-1 {
        margin: 0px auto !important;
    }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cci-cards-container {
        gap: 30px;
        justify-content: start;
    }
    .cci-chatbase-cards-item {
        margin-top: 20px !important;
    }
    .cci-item-2-cards-container {
        gap: 35px;
    }
    .cci-main-item-1 {
        margin: 0px auto !important;
    }
    .cci-main-cards-item {
        margin-top: 35px !important;
    }
    .cci-subheading-item {
        margin: 20px 0px !important;
    }
    .cci-card-item {
        border: none;
        padding: 0px !important;
        background-color: inherit;
        width: 100%;
        max-width: 100%;
    }
    .cci-cards-img {
        width: 23px;
        height: auto;
    }
    .cci-cards-content-item {
        margin-top: 0px !important;
    }
    .cci-cards-item-container {
        justify-content: center !important;
    }
    .cci-main-item-2 {
        margin: 25px 0px 25px !important;
        padding: 0px;
    }
}
