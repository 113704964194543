.no-cred-text {
    font-size: 14px;
    font-weight: 500;
    color: gray;
    margin: 5px 0px 0px !important;
}

.in-head-sec-label {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: black !important;
}

.in-automate-text {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #647491 !important;
}

.in-btn-item {
    margin: 20px 0px !important;
}

.in-head-sec-label-item {
    margin: 0px 0px 10px !important;
}

.in-head-sec-text-item {
    margin: 10px 0px 20px !important;
}

.in-head-sec-text {
    font-size: 40px !important;
    font-weight: 600 !important;
    color: black !important;
}

.insta-head-img {
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    height: 500px;
    object-fit: contain;
}

.insta-head-img-item {
    text-align: center;
}


.in-automate-text-item {
    margin: 10px 0px 20px !important;
}

.in-head-sec-main-container {
    justify-content: space-between;
    gap: 20px;
}

.in-head-sec-main-item {
    margin: 230px auto 120px !important;
}

.in-head-sec-main-item2 {
    margin: 150px auto 120px !important;
}

.in-btn-container {
    gap: 20px;
}

.get-started-free-btn {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
    background-color: rgba(106, 9, 125, 1) !important;
    text-transform: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
}

.in-content-btn {
    font-size: 18px !important;
    font-weight: 600px !important;
    color: rgba(106, 9, 125, 1) !important;
    text-transform: none !important;

}


@media only screen and (min-width: 0px) and (max-width: 1200px) {
    .insta-head-img-item {
        margin: 20px 0px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 899px) {
    .insta-head-img-item {
        text-align: center;
        margin: 20px 0px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .in-head-sec-text {
        font-size: 30px !important;
        font-weight: 600 !important;
        color: black !important;
    }

    .in-automate-text {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: #647491 !important;
    }
}