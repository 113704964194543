.bh-page-main-item {
    padding: 120px 0px !important;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(253, 252, 255, 1) 52%, rgba(252, 242, 233, 1) 84%, rgba(251, 236, 219, 1) 100%, rgba(255, 255, 255, 1) 100%);
}


.bh-header-item {
    /* height: 300px !important; */
    align-items: center;
    display: flex;
}

.bh-card-main-item {
    background-color: white;
    border-radius: 10px;
    max-width: 400px !important;
    width: 400px !important;
    min-width: 400px !important;
    height: 300px !important;
    min-height: 300px !important;
    max-height: 300px !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.bh-card-main-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.bh-card-wrapper-item {
    max-width: 1280px !important;
    width: 1280px !important;
    margin: 0 auto !important;
    padding: 0px 10px !important;
}

.bh-card-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.bh-main-blog-card {
    background-color: rgb(247, 225, 205);
    border-radius: 10px !important;
    height: 300px;
    max-height: 300px;
    width: 800px !important;
    max-width: 800px !important;
    margin: 0 auto 50px !important;
    cursor: pointer;
}

.bh-main-blog-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 20px rgba(0, 0, 0, 0.15);
}

.bh-card-blog-text {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: black !important;
}

.bh-main-card-text-item {
    padding: 20px !important;
    display: flex !important;
    align-items: center;
}

.bh-card-home-card-item {
    text-align: start;
}

.bh-card-search-card-item {
    text-align: end;
}

@media only screen and (min-width: 600px) and (max-width: 870px) {
    .bh-main-blog-card {
        width: 90% !important;
        max-width: 90% !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .bh-card-home-card-item {
        text-align: center;
        margin: 5px 0px !important;
    }

    .bh-card-search-card-item {
        text-align: center;
        margin: 5px 0px !important;
    }

    .bh-main-blog-card {
        width: 90% !important;
        max-width: 90% !important;
        height: auto !important;
        max-height: 100% !important;
    }

    .bh-card-wrapper-item {
        margin: 100px auto 70px !important;
    }

    .bh-main-card-text-item {
        padding: 20px !important;
        display: block !important;
        align-items: center;
    }

    .bh-card-main-item {
        background-color: white;
        border-radius: 10px;
        max-width: 95% !important;
        width: 95% !important;
        min-width: 95% !important;
        height: auto !important;
        min-height: auto !important;
        max-height: auto !important;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    .bh-card-img {
        height: 150px;
    }


}