.writer-wiz-appbar {
    background-color: white !important;
}

.writer-wiz-appbar-onscroll {
    background-color: #6A097D !important
}

.wz-nav-menu-btn {
    font-size: 15px !important;
}

.wz-nav-menu-add-browser-btn,
.wz-nav-menu-add-browser-btn-transition {
    font-size: 15px !important;
}

.wz-website-head {
    color: #6A097D;
    font-size: 20px;
    font-weight: 700;
}

.wz-nav-menu-btn {
    font-size: 15px;
    font-style: normal;
    font-weight: 600 !important;
    color: black !important;
    text-transform: capitalize !important;
}

.wz-nav-menu-btn:hover {
    background-color: #F9EDFF !important;
}

.wz-header-toolbar {
    padding: 15px 15px !important;
}

.wz-header-toolbar .css-i6bazn {
    overflow: visible !important;
}

.wz-nav-menu-add-browser-btn:hover {
    background-color: #F9EDFF !important;
}

.wz-nav-menu-add-browser-btn {
    font-size: 15px;
    font-weight: 600 !important;
    text-transform: none !important;
    /* background-color: #6A097D !important; */
    color: #6A097D !important;
    background: #FFF;
    /* margin-right: 60px !important; */
    box-shadow: 0px 0px 2px 0px #6A097D;
    border-radius: 5px;
}

.wz-nav-menu-add-browser-btn-transition {
    font-size: 15px;
    text-transform: none !important;
    background-color: white !important;
    color: #6A097D !important;
    font-weight: 600 !important;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #6A097D !important;

}

.wz-header-scroll {
    background-color: white !important;
    z-index: 100 !important;
    transition: .3s ease-in !important;
    overflow: hidden !important;
    position: fixed !important;
    height: 65px !important;
    border-radius: 15px !important;
    /* width: 80% !important;
    right: 10% !important; */
    box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
    border: 1px solid rgba(106, 9, 125, 0.15) !important;
    /* top: 8px !important; */
}

.wz-header {
    z-index: 99 !important;
    transition: .3s ease-in !important;
    overflow: hidden !important;
    position: fixed !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border-radius: 15px !important;
    box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
    /* width: 80% !important;
    right: 10% !important; */
    min-height: 60px !important;
    border: 1px solid rgba(106, 9, 125, 0.15) !important;
    max-width: 85% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.wz-header-mobile {
    position: fixed !important;
    z-index: 1 !important;
    overflow: hidden !important;
    position: fixed !important;
    box-shadow: none !important;
    background-color: #fff !important;
    width: 100% !important;
    min-height: 60px !important;
    box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
    z-index: 99 !important;
}

.wz-header-scroll-mobile {
    background-color: white !important;
    position: fixed !important;
    z-index: 100 !important;
    position: fixed !important;
    min-height: 60px !important;
    width: 100% !important;
    box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
    box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
    border: 1px solid rgba(106, 9, 125, 0.15) !important;

}

.wz-header-logo-text-after-scroll {
    color: white !important;
}

.wz-header-btn-text-before-scroll {
    color: #1976d2 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

.wz-header-btn-text-after-scroll {
    color: white !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

.wz-btn-visible {
    transform: translate3d(168px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
    transform-style: preserve-3d !important;
    opacity: 0 !important;

}

.wz-free-transition-btn-hidden {
    opacity: 0;
    margin-left: 0px;
}

.wz-free-transition-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin-left: 15px !important;
    /* width: 170px; */
    /* transform: translateX(50) ; */
    font-weight: 500 !important;
    animation: buttonEntry 0.5s;
}

.wz-signup-btn-without-transition {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin-left: 15px !important;
    font-weight: 500 !important;
}

.navbar-computer-right-button-group {
    animation: moveButtonGroup 0.5s ease-in-out;
}

.navbar-computer-right-button-group-with-hidden-button {
    transform: translate(210px, 0px);

    /* transform: translate(115px, 0px); */
    animation: moveButtonGroupReverse 0.5s ease-in-out;
}

.navbar-contact-us:hover {
    background-color: #6A097D !important;
}


.navbar-menu-button {
    background-color: white !important;
    border-radius: 5px !important;
    color: #000 !important;
    box-shadow: 0px 0px 2px 0px #6A097D !important;
    text-transform: none !important;
}

.navbar-menuitem {
    padding: 5px 10px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin: 5px 0px !important
}

.navbar-menuitem:hover {
    background-color: #F9EDFF !important;
}

.navbar-global-btn {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

.navbar-global-btn:hover {
    background-color: #F9EDFF !important;
}


.navbar-hover-menu-container {
    justify-content: center;
    width: 100%;
}

.navbar-hover-menu-item {
    width: 250px !important;
    max-width: 250px !important;
    background-color: white;
    position: fixed;
    top: 50px;
    margin: 0 auto !important;
    padding: 15px;
    height: 200px;
    z-index: 1;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.isOnHoverNavbar {
    display: flex;
    opacity: 0.8;
    transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all;
    will-change: transform, opacity;
}

.staticstyle {
    opacity: 0;
    display: none;
}

.main-navbar-wrapper {
    position: relative;
    width: 100%;
}

.navbar-hover-menu-item {
    margin: 0 auto !important;
}

.option-navbar-btn {
    font-size: 14.5px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    padding: 5px 10px !important;
    text-transform: none !important;
}

.option-navbar-btn .MuiButton-icon {
    margin: 2px 0px 0px !important;
}

.navbar-heading-text {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
}

.navbar-options-text {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    vertical-align: top;
}

.navbar-option-item {
    /* background-color: rgba(66, 196, 82, 0.2); */
    padding: 8px;
    margin: 5px 0px !important;
    border-radius: 5px;
    /* box-shadow: 0px 0px 2px 0px #2196F3; */
}

.navbar-option-item:hover {
    background-color: rgba(66, 196, 82, 0.2);
    padding: 8px;
    margin: 5px 0px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #4DCB5B;
}

.navbar-option-item2 {
    background-color: #1976D233;
    padding: 8px;
    margin: 5px 0px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #2196F3;
}

.navbar-option-item:hover,
.navbar-option-item2:hover {
    cursor: pointer;
}

.navbar-box {
    position: relative;
    display: flex;
}

.nav-button-key-arrow {
    transition: transform 0.3s ease !important;
    font-size: 20px !important;
}


.mobileHamburgerSideBar {
    background-color: #6A097D !important;
}

.mobileHamburgerSideBar-logo-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
}

.mobileHamburgerSideBar-button {
    background-color: white;
    margin: 10px;
    width: 70%;
    color: black;
    border-radius: 5px;
    padding: 15px;
}


.mobileHamburgerSideBar-inner-div {
    width: 100%;
}

.mobileHamburgerSideBar-center-box {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mobileHamburgerSideBar-back-button {
    border-radius: 50%;
    height: 40px;
    position: fixed !important;
    right: 10px;
    border: none;
    outline: none;
    box-shadow: none !important;
    background-color: transparent !important;
}

.mobileHamburgerSideBar-footer-parent {
    margin-top: 310px;
    width: 90%;
    margin-inline: auto;
    color: white;
    display: flex;
    text-align: left;
}

.mobileHamburgerSideBar-footer-item {
    color: white
}

.mobileHamburgerSideBar-footer-left {
    width: 50%;
    margin-left: 40px;
}

.mobileHamburgerSideBar-footer-right {
    width: 50%;
    margin-left: 50px;

}

.mobileHamburgerSideBar-parent-div {
    display: flex;
    justify-content: space-between;
}

.navbar-drawer-button {
    display: none !important;
    margin: 0px !important;
}

.wz-header-toolbar {
    height: 100% !important;
    width: 100% !important;
}

.iframe-dialog .MuiDialog-paperScrollPaper {
    width: 100% !important;
}

.mobileHamburgerSideBar-button-footer {
    background-color: white;
    margin: 10px;
    width: 100%;
    color: black;
    border-radius: 5px;
    padding: 15px;
}

.nav-logo-item {
    width: 200px !important;
    max-width: 200px !important;
}

.nav-btn-menu-item {
    max-width: calc(100% - 220px) !important;
    width: calc(100% - 220px) !important;
}

.nav-menu-item-main-container {
    flex-wrap: nowrap !important;
    justify-content: space-between;
    gap: 15px;
    align-items: center !important;
}

.login-btn {
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 2px !important;
    color: #6A097D !important;
    text-transform: none !important;
    height: 100% !important;
    align-items: center;
}


/* KEY FRAMES */

@keyframes buttonEntry {
    0% {
        transform: translateX(0px);
        /* width: 0px; */
    }

    50% {
        transform: translateX(90px);
    }

    100% {
        transform: translateX(0px);
        /* width: auto */
    }
}

@keyframes moveButtonGroup {
    0% {
        transform: translate(210px, 0px);

        /* width: 0px; */
    }

    100% {
        transform: translate(0px, 0px);

        /* width: auto */
    }
}

@keyframes moveButtonGroupReverse {
    0% {
        transform: translate(0px, 0px);
        /* width: auto */
    }

    100% {
        transform: translate(210px, 0px);
        /* width: 0px; */
    }


}

/* KEY FRAMES */



/* MEDIA QUERY */
@media (max-width: 1460px) and (min-width: 1200px) {
    .option-navbar-btn {
        font-size: 13px !important;
    }
}

@media (min-width: 1460px) {
    .wz-header {
        z-index: 99 !important;
        transition: .3s ease-in !important;
        overflow: hidden !important;
        position: fixed !important;
        box-shadow: none !important;
        background-color: #fff !important;
        border-radius: 15px !important;
        box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
        min-height: 60px !important;
        border: 1px solid rgba(106, 9, 125, 0.15) !important;
        max-width: 85% !important;
        /* left: 50% !important;
        transform: translateX(-50%) !important; */
    }

}

@media only screen and (min-width: 1250px) and (max-width: 1535px) {
    .wz-header {
        z-index: 99 !important;
        transition: .3s ease-in !important;
        overflow: hidden !important;
        position: fixed !important;
        box-shadow: none !important;
        background-color: #fff !important;
        border-radius: 15px !important;
        box-shadow: 0px 0px 25px rgba(106, 9, 125, 0.05) !important;
        min-height: 60px !important;
        border: 1px solid rgba(106, 9, 125, 0.15) !important;
        max-width: 88.5% !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1360px) {
    .wz-header {
        max-width: 1200px !important;
    }
}


@media only screen and (min-width: 0px) and (max-width: 599px) {

    .chronox-logo {
        width: 135px;
        height: auto;
    }

    .mobileHamburgerSideBar-footer-parent {
        /* margin-top: 300px; */
        margin-top: 120px !important;
        width: 100% !important;
    }

    .mobileHamburgerSideBar-footer-right {
        width: 70% !important;

    }

    .mobileHamburgerSideBar-button-footer {
        background-color: white;
        margin: 10px;
        width: 100%;
        color: black;
        border-radius: 5px;
        padding: 15px;
    }
}

@media only screen and (min-width: 901px) and (max-width: 910px) {

    .nav-logo-item {
        max-width: 150px !important;
        width: 150px !important;
    }

    .chronox-logo {
        width: 100% !important;
    }

    .nav-logo-wrapper {
        width: 100% !important;
    }

    .nav-btn-menu-item {
        max-width: calc(100% - 170px) !important;
        width: calc(100% - 170px) !important;
    }
}

@media screen and (max-width: 900px) {

    .navbar-computer-right-button-group-with-hidden-button,
    .navbar-computer-right-button-group-without-transition {
        display: none;
    }

    .navbar-computer-right-button-group {
        display: none;
    }

    .navbar-drawer-button {
        display: flex !important;
    }

    .wz-header-toolbar {
        width: 97% !important;
    }

    .nav-logo-item {
        width: 100% !important;
        max-width: 100% !important;
    }

    .wz-header {
        border-radius: 0px !important;
    }

    .wz-header-toolbar {
        padding: 10px !important;
    }
}

/* FOR BUTTON TEXT */

@media only screen and (min-width: 900px) and (max-width: 1249px) {
    .option-navbar-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        padding: 5px 7px !important;
    }
    .wz-header-toolbar {
        padding: 10px !important;
    }
    .nav-menu-item-main-container {
        gap: 10px;
    }
}

@media (max-width: 1200px) and (min-width: 900px) {
    .option-navbar-btn {
        font-size: 12.5px !important;
        margin: 3px 0px 1;
    }
}

@media only screen and (min-width: 900px) and (max-width: 950px) {
    .option-navbar-btn {
        font-size: 13px !important;
        font-weight: 600 !important;
        padding: 5px !important;
    }
}


/* FOR BUTTON TEXT */

/* MEDIA QUERY */