.cpc-main-head-section {
    font-size: 35px;
    font-weight: 500;
    color: white;
}

.cpc-right-head-text {
    display: flex;
    color: #FFB762;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}

.cpc-main-container {
    justify-content: space-between;
}

.cpc-points-list-main-item {
    width: 260px !important;
    max-width: 260px !important;
    height: 45px !important;
}

.cpc-points-list-main-container {
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.cpc-points-part1 {
    width: 8%;
    text-align: center;
}

.cpc-points-part2 {
    /* background-color: #EFD7FE; */
    border-radius: 30px;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.cpc-points-part2 span {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
    text-transform: none !important;
}

.cpc-points-main-container {
    justify-content: space-between;
}

.cpc-right-main-item {}

.cpc-book-meeting-btn {
    color: #6A097D !important;
    background-color: white !important;
    border-radius: 8px !important;
    text-transform: none !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 10px 20px !important;
    box-shadow: none !important;
}

.cpc-gradiant-item {
    max-width: 1250px !important;
    margin: 50px auto !important;
    padding: 60px !important;
    background-image: url("../../assests/Chatbot-Background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px !important;
}

@media only screen and (min-width : 1536px) and (max-width : 1580px) {
    .cb-gradiant-main-item {
        margin: 50px auto !important;
        padding: 60px 30px !important;
    }
}

@media only screen and (min-width : 0px) and (max-width : 600px) {
    .cpc-gradiant-item {
        padding: 60px 30px !important;
    }

    .cpc-right-head-text {
        display: block;

    }

    .cpc-main-head-section {
        font-size: 30px;
        font-weight: 500;
        color: white;
    }
}