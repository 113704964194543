.usecasemobile-main-div {
    /* background-color: #F9EDFF; */
    padding: 30px 0 50px 0;
}

.usecasemobile-inner-div {
    /* height: 100vh; */
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.usecasemobile-main-text {
    color: black;
    width: 85%;
    align-items: center;
}

#usecasemobileText {
    margin-top: 10px;
}

.usecasemobile-main-text-inner-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 10px;
}

.usecasemobile-main-image {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    /* margin-bottom: 65px; */
    width: 85%;
}

.usecasemobile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.usecasemobileSmallTitle {
    font-weight: 700;
    font-size: 18px;
    background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
    ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.usecasemobile-main-image-inner-div {
    width: 90%;
}

.usecasemobileTitle {
    font-size: 25px;
    color: black;
    font-weight: 600;
    line-height: normal;
    width: 80%;
    margin-top: 15px;
}

.usecasemobileDescription {
    font-weight: 400;
    color: #7A7A7A;
    font-size: 15px;
    margin-top: 20px;
}

.CarouselStyles .slide {
    display: flex !important;
    justify-content: end !important;
}

.carousel-main-title-text {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
    .usecasemobile-main-image-inner-div {
        width: 60%;
    }

    .usecasemobileTitle {
        width: 100%;
    }

}

@media screen and (max-width: 599px) {
    .usecasemobile-main-image-inner-div {
        width: 90%;
    }

    .usecasemobileTitle {
        font-size: 18px;
    }

    .usecasemobileDescription {
        font-size: 13px;
    }

    .usecasemobileSmallTitle {
        font-weight: 600;
        font-size: 13px;
    }

    .usecasemobile-main-div {
        padding: 40px 0 0px 0;
    }

    .usecasemobileTitle {
        width: 100%;
    }

    .usecasemobile-main-text {
        color: black;
        width: 90%;
        align-items: center;
    }

    .carousel-main-title-text {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        margin-top: 15px;
    }

}

.necarouselscrollelement-main-div {
    /* background-color: #f9edff; */
    /* height: 300vh; */
    display: flex;
    flex-direction: column;
}

.abcdefg {
    /* height: 60vh; */
    width: 70%;
    background-color: transparent;
    border-radius: 20px;
    /* margin-top: 250px; */
}

.necarouselscrollelement-main-div-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 100px;
    /* flex-direction: column; */
}

.newscrollelement-main-text {
    height: 100vh;
    color: black;
    width: 50%;
    align-items: start;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* margin-top: 400px; */
}

.tempDiv {
    height: 170px;
    width: 50%;
}

.newscrollelement-main-image-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: end;
    /* background: transparent; */
}

.newscrollelement-main-image-container {
    /* width: 650px;
  height: 650px; */
    object-fit: contain;
}

.newscrollelement-main-image {
    /* width: 650px;
  height: 650px;
  max-width: 110%; */
    width: 100%;
    height: 500px;
    object-fit: contain;
    border-radius: 20px !important;
}

.animateTextFadeIn {
    animation: fadeIn 1s ease-in-out;
}

.animateTextFadeOut {
    animation: fadeIn 1s;
}

#newScrollText {
    color: #000;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
}

.small-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 3.78px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(90deg, #6a097d 50%, #0a6add 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 25px;
}

.small-title-icon {
    padding-right: 15px;
    transform: translateY(5px);
}

#newScrollTitle {
    color: #333;
    font-weight: 600;
    font-size: 52px;
    line-height: normal;
    margin-top: 10px;
}

#newScrollTitleLastWord {
    color: #6a097d;
    font-weight: 600;
    font-size: 58px;
    line-height: normal;
    margin-top: 15px;
    margin-left: 15px;
}

#newScrollDescription {
    font-weight: 500;
    color: #7a7a7a;
    font-size: 18px;
    margin-top: 20px;
    line-height: 150%;
    /* 27px */
}

.newscrollelement-main-text-inner-div {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.newscroll-learn-more-button-forward-icon {
    padding-left: 10px;
}

.css-1oi22rp-MuiButtonBase-root-MuiButton-root:hover {
    background-color: transparent !important;
    border: none !important;
}

/* .newscroll-learn-more-button {
  color
} */
/* @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

@keyframes fadeIn {
    0% {
        transform: translateY(150px);
        /* Start 30px below */
        opacity: 0;
    }

    40% {
        transform: translateY(0);
        /* Back to main position */
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 1650px) {

    #newScrollTitle,
    #newScrollTitleLastWord {
        font-size: 45px;
    }
}

@media screen and (max-width: 1500px) {

    #newScrollTitle,
    #newScrollTitleLastWord {
        font-size: 40px;
    }
}

@media screen and (max-width: 1300px) {

    #newScrollTitle,
    #newScrollTitleLastWord {
        font-size: 35px;
    }

    #newscrollelement-main-text-inner-div {
        width: 85%;
    }

    .necarouselscrollelement-main-div-inner {
        column-gap: 50px;
    }
}