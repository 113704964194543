.chatlayout {
    animation: chat-loader 1s linear normal;
}


.chat-animation-text {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.message:hover {
    animation: message-hover 1.5s infinite;
}

.chatbutton {
    background-color: #fff !important;
    border: 1px solid #6A097D !important;
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    text-transform: none !important;
}


.mesg-animation {
    animation: message-render 1s linear normal;
}

/* --------------------- */

@keyframes chat-loader {
    0% {
        transform: translateY(20px) translate(-10px);
        opacity: .2;
        max-height: 0;
    }

    50% {
        transform: translateY(20px) translate(0);
    }

    100% {
        transform: translateY(0) translate(0);
        opacity: 1;
        max-height: 4000px;
    }
}

@keyframes message-render {
    0% {
        transform: translateY(20px) translate(-10px);
        opacity: .2;
        max-height: 0;
    }

    50% {
        transform: translateY(20px) translate(0);
    }

    100% {
        transform: translateY(0) translate(0);
        opacity: 1;
        max-height: 4000px;
    }
}

@keyframes message-hover {


    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.06);
    }

    100% {
        transform: scale(1);
    }
}

/* --------------------- */