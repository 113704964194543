.simplified-head-text-main-item {
    margin: 20px auto 35px !important;
    background: linear-gradient(90deg, #061A44 0%, rgba(6, 26, 68, 0.8) 100%);
    background-color: rgba(8, 15, 52, 0.04) !important;
    color: white !important;
    border-radius: 15px !important;
}

.simplified-head-text-item {
    padding: 50px 0px 20px !important;
    margin: 0 auto !important;
}

.simplified-head-text-item1 {
    padding: 0px 0px 0px !important;
    margin: 0px auto 20px !important;
}

.simplified-button-text-item {
    margin: 0 auto 10px !important;
}

.simplified-head-text {
    font-size: 40px !important;
    font-weight: 600 !important;
}

.ai-chatbot-simplified-button {
    /* background-color: white !important; */
    text-decoration: none !important;
    text-transform: none !important;
    color: white !important;
    border-radius: 0px !important;
    padding: 6px 0px 2px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-bottom: 1px solid white !important;
    cursor: pointer !important;
}

.slider-buttons-style {
    margin-block: 15px 55px !important;
}

.simplified-buttons-style {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.marquee-button-styles {
    background-color: #ffffffc4 !important;
    color: black !important;
    padding: 10px 22px !important;
    border-radius: 5px !important;
    white-space: nowrap !important;
}

.scrolling-container {
    overflow: hidden;
    width: 100%;
}

.scrolling-content {
    display: flex;
    animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 900px) {


    .simplified-head-text {
        font-size: 34px !important;
    }

    .ai-chatbot-simplified-button {
        /* background-color: white !important; */
        text-transform: none !important;
        color: white !important;
        border-radius: 0px !important;
        padding: 6px 0px 2px !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        border-bottom: 1px solid white !important;
        cursor: pointer !important;
    }

    .slider-buttons-style {
        margin-block: 15px 55px !important;
    }

    .marquee-button-styles {
        background-color: #ffffffc4 !important;
        color: black !important;
        padding: 10px 22px !important;
        border-radius: 5px !important;
        white-space: nowrap !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .simplified-head-text {
        font-size: 30px !important;
        font-weight: 600 !important;
    }
}