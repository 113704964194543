.gi-card-attendee-item {}

.gi-card-main-item {
    margin: 50px auto !important;
}

.gac-main-item {
    background: url('../../assests/Gitex/attendees-background.svg') !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: max-content !important;
    padding: 40px 10px !important;
}

.gac-section-item {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    text-align: center;
}

.gac-card-head-text {
    font-size: 35px !important;
    font-weight: 500 !important;
    color: white !important;
}

.gac-card-sub-head-text {
    font-size: 22px !important;
    font-weight: 500 !important;
    color: white !important;
}

.gac-card-sub-content-text {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: white !important;
}

.gac-card-img {
    width: 80px;
    height: auto;
    object-fit: fill;
}