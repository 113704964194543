.service-head-text-main-item {
    margin: 20px auto !important;
}

.service-head-text-item {
    text-align: center !important;
    margin: 20px auto 10px !important;
}

.service-subhead-text-item {
    text-align: center !important;
    margin: 10px auto 40px !important;
}

.service-head-normal-text-color {
    font-size: 40px !important;
    line-height: 1 !important;
    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.9) 30%, rgba(148, 148, 148, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-head-normal-dark-text-color {
    font-size: 42px !important;
    line-height: 1 !important;
    background: linear-gradient(to bottom right, rgba(106, 9, 125, 1) 50%, rgba(10, 106, 221, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-head-sub-normal-text-color {
    font-size: 21px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    color: #1F384C !important;
}

.selected-service-heading-name {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
    /* transition: all 0.5s ease-in; */
}

.MuiAccordionSummary-root {
    transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}

.service-heading-name {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #333333 !important;
    transition: all 0.5s ease-in-out;
}

.service-heading-sub-name {
    font-size: 17px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px) scaleY(0);
    transform-origin: top;
    transition: height 0.5s ease, opacity 0.5s ease, transform 0.8s ease-in-out;
    overflow: hidden;
}

.visible {
    height: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.hidden {
    height: 0;
    opacity: 0;
    transform: translateY(-10px) scaleY(0);
}

.selected-service-data {
    background-color: rgba(247, 233, 255, 1) !important;
    margin-top: 10px !important;
    /* margin-left: 20px !important; */
    padding: 10px 10px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    box-shadow: none !important;
    /* transform: scale(1.05); */
    /* Slight zoom */
}

.select-service-data {
    background-color: transparent !important;
    margin-top: 10px !important;
    /* margin-left: 20px !important; */
    padding: 10px 10px !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    box-shadow: none !important;
    /* transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; */
}

.select-service-data:hover {
    background-color: rgba(247, 233, 255, 1) !important;
    box-shadow: none !important;
    transition: padding 0.3s ease-in-out, transform 0.3s ease-in;
    /* transform: scale(1.05) translateX(0) !important; */
}

.select-service-data::before {
    background-color: transparent !important;
}

.service-data-accordion {
    background-color: transparent !important;
    box-shadow: none !important;
}

.service-data-accordion .MuiAccordionSummary-content {
    margin: 0 !important;
}


.service-body-main-item {
    background: linear-gradient(to right, rgba(224, 217, 240, 0.3), rgba(219, 230, 242, 0.3)) !important;
    border-radius: 20px !important;
    padding-block: 30px !important;
    position: relative !important;
}

.chatbot-component-position-style {
    position: absolute !important;
    right: 35px !important;
}

@media screen and (max-width: 1200px) {
    .chatbot-component-position-style {
        position: relative !important;
    }
}

@media screen and (max-width: 900px) {
    .chatbot-component-position-style {
        right: 0 !important;
    }

    .selected-service-data {
        /* transform: scale(0.97); */
        margin-left: 3px !important;
        margin-left: 0px !important;
    }

    .select-service-data {
        margin-left: 3px !important;
        margin-left: 0px !important;
    }

    .service-head-normal-text-color {
        font-size: 35px !important;
        line-height: 1 !important;
    }

    .service-head-normal-dark-text-color {
        font-size: 44px !important;
        line-height: 1 !important;
    }

    .service-head-sub-normal-text-color {
        font-size: 20px !important;
    }

    .selected-service-heading-name {
        font-size: 24px !important;
    }

    .service-heading-name {
        font-size: 24px !important;
    }

    .service-heading-sub-name {
        font-size: 16px !important;
    }

    .selected-service-data {
        padding: 5px !important;
    }

    .select-service-data {
        padding: 5px !important;
    }

    .selected-service-data .Mui-expanded {
        margin: 0 !important;
    }

    .select-service-data .Mui-expanded {
        margin: 0 !important;
    }

}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .service-head-normal-text-color {
        font-size: 26px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .service-head-normal-dark-text-color {
        font-size: 26px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .service-head-sub-normal-text-color {
        font-size: 16px !important;
        font-weight: 600 !important;
    }

    .selected-service-heading-name {
        font-size: 18px !important;
    }

    .service-heading-name {
        font-size: 18px !important;
    }

    .service-heading-sub-name {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .service-head-normal-text-color {
        font-size: 22px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .service-head-normal-dark-text-color {
        font-size: 30px !important;
        line-height: 1.3 !important;
        font-weight: 700 !important;
    }

    .service-head-sub-normal-text-color {
        font-size: 18px !important;
    }

    .selected-service-heading-name {
        font-size: 20px !important;
    }

    .service-heading-name {
        font-size: 20px !important;
    }

    .service-heading-sub-name {
        font-size: 16px !important;
    }
}