.cbem-explore-heading-container {
    /* display: inline-flex; */
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    text-align: center;
    margin: 80px auto auto;
    /* padding: 0px 40px; */
}

.cbem-explore-heading {
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    border-bottom: 3px solid #6a097d;
}

.cbem-explore-heading span {
    color: #6a097d;
}

.cbem-explore-sub-heading {
    font-size: 18px;
    color: #1f384c;
    margin-top: 15px;
    font-weight: 500;
}
.chatbot-usecasemobile-main-div {
    background-color: #ffffff;
    padding: 30px 0 50px 0;
    border-bottom: 5px solid;
    border-image: linear-gradient(to right, #3b78ce 33%, #e8e021 66%);
    border-image-slice: 1;
}
.chatbot-usecasemobile-main-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chatbot-usecasemobile-main-text-inner-div {
    width: 90%;
}
.chatbot-usecasemobile-main-image {
    width: 95%;
    display: flex;
    justify-content: center;
    margin: auto;
}
.chatbot-usecasemobileTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    text-align: left;
    margin-top: 15px;
}
.chatbot-usecasemobileDescription {
    width: 90%;
    font-size: 14px;
    margin-top: 12px;
    font-weight: 500;
    color: #1f384c;
    text-align: left;
}
.chatbot-usecasemobile-section {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CarouselStyles .carousel {
    padding-bottom: 40px;
}

.CarouselStyles .carousel .control-dots {
    margin: 3px 0px;
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .chatbot-usecasemobile-main-image-inner-div,
    .chatbot-usecasemobile-main-text-inner-div {
        width: 60%;
    }
    .cbem-explore-heading {
        font-size: 30px !important;
        text-align: center !important;
        width: fit-content;
    }
    .cbem-explore-sub-heading {
        font-size: 18px;
    }
    .chatbot-usecasemobileTitle {
        font-size: 25px;
    }
    .chatbot-usecasemobileDescription {
        font-size: 18px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .chatbot-usecasemobile-main-image-inner-div,
    .chatbot-usecasemobile-main-text-inner-div {
        width: 80%;
    }
    .cbem-explore-heading {
        font-size: 30px !important;
        text-align: center !important;
        width: fit-content;
    }
    .cbem-explore-sub-heading {
        font-size: 17px;
    }
    .chatbot-usecasemobileTitle {
        font-size: 25px;
    }
    .chatbot-usecasemobileDescription {
        font-size: 18px;
    }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cbem-explore-heading {
        font-size: 25px !important;
        text-align: center !important;
        width: fit-content;
    }
    .cbem-explore-sub-heading {
        font-size: 18px;
    }
    .chatbot-usecasemobileTitle {
        font-size: 20px;
    }
    .chatbot-usecasemobileDescription {
        font-size: 14px;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .cbem-explore-heading-container {
        margin: auto !important;
        display: flex;
        align-items: center;
    }
    .chatbot-usecasemobileDescription {
        width: 100%;
    }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cbem-explore-heading-container {
        margin: 40px auto auto !important;
    }
    .chatbot-usecasemobile-main-div {
        padding: 0px;
        border: none;
    }
    .chatbot-expolre-border-bottom {
        display: none;
    }
    .chatbot-usecasemobile-main-image-inner-div,
    .chatbot-usecasemobile-main-text-inner-div {
        width: 90%;
    }
    .chatbot-usecasemobileDescription {
        width: 100%;
    }
    .cbem-explore-heading-container .cbem-explore-heading {
        text-align: center !important;
    }
    .cbem-explore-heading-container .cbem-explore-sub-heading {
        margin-top: 20px !important;
    }
}
