/* Head */
.cw-head-section-main-item {
    margin: 100px auto 0px !important;
}



/* Head */

.cw-bz-section-main-item {
    padding: 80px 0px 60px !important;
    margin: 100px auto !important;
    background-color: rgba(247, 233, 255, 0.3);
}


/* info */

.cw-info-section-main-item {
    margin: 0 auto !important;
    /* max-width: 1200px !important; */
    /* background-color: #FEFEFE; */
    background-image: url('../../assests/ChatbotWhatsapp/cw-waves-bg.png');
    background-position: start;
    background-size: contain;
    background-repeat: no-repeat;
}

.cw-normal-text-color {
    color: #1F384C !important;
}

.cw-highlighted-text-color {
    color: #6A097D !important;
}

/* info */



/* tryit */
.cw-tryit-main-item {
    background-color: #6A097D;
    padding: 100px 80px !important;
    margin: 100px auto !important;
}

/* tryit */


/* custom */
.cw-custom-main-item {
    margin: 50px auto !important;
}

/* custom */


.wpc-accordion {
    box-shadow: none !important;
    position: inherit !important;
    border: 2px solid #fff !important;
    margin-top: 15px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.wpc-expand-accordion-summary {
    cursor: auto !important;
    min-height: 60px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: #F4F7F9 !important;
    padding: 0px 15px !important;
}

.wpc-expand-accordion-summary .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg) !important;
}

.wpc-expand-accordion-summary .Mui-expanded {
    transform: rotate(360deg) !important;
    margin: 0px 0px !important;
}


@media screen and (max-width : 600px) and (min-width : 0px) {
    .cw-tryit-main-item {
        background-color: #6A097D;
        padding: 50px 20px !important;
        margin: 100px auto !important;
    }

    .cw-info-section-main-item {
        margin: 40px auto !important;
    }
}