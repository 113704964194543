.CFB-mainOuterContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  height: 100%;
  box-shadow: 0px 0px 10px 0px #00000040;
}

.CFB-learnMore {
  color: inherit;
  text-decoration: none;
}

.CFB-learnMore:hover,
.CFB-learnMore:focus {
  color: inherit;
  text-decoration: none;
}

.CFB-learnMore p {
  display: inline-block;
}

.CFB-learnMore p,
.CFB-learnMore svg {
  padding: 0;
  margin: 0;
}

.CFB-learnMore svg {
  vertical-align: middle;
}

.CFB-FeatureImage {
  width: 100%;
  height: 100%;
}

.CFB-titleContainer {
  margin-block: 20px;
  border-bottom: 2px solid #6a097dcc;
}

.CFB-HeaderText {
  color: #6a097d;
  font-size: 18px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.CFB-subTitleText {
  color: #1f384c;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1900px) {
  .CFB-subTitleText {
    font-size: 25px;
  }
  .CFB-description {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .CFB-subTitleText {
    font-size: 19px;
  }
  .CFB-description {
    font-size: 15px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .CFB-logo-img {
    width: 50px !important;
    height: auto;
  }
  .CFB-subTitleText {
    font-size: 20px;
  }
  .CFB-description {
    font-size: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .CFB-logo-img {
    width: 50px !important;
    height: auto;
  }
  .CFB-subTitleText {
    font-size: 20px;
  }
  .CFB-description {
    font-size: 15px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CFB-logo-img {
    width: 50px !important;
    height: auto;
  }
  .CFB-subTitleText {
    font-size: 18px;
  }
  .CFB-description {
    font-size: 14px;
  }
}
