.liveai-head-text-main-item {
    margin: 50px auto !important;
}

.liveai-head-text-item {
    text-align: center !important;
    margin: 0px auto 0px !important;
}

.liveai-head-text-comparison-item {
    text-align: center !important;
    margin: 30px auto !important;
}

.liveai-head-normal-text-color {
    font-size: 40px !important;
    line-height: 1.05 !important;
    color: #000000 !important;
    font-weight: 600 !important;
    /* background: linear-gradient(to bottom right, #000000 60%, #949494 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.liveai-head-sub-normal-text-color {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    color: #1F384C !important;
}

@media screen and (max-width: 1650px) and (min-width: 1200px) {
    .liveai-head-sub-normal-text-color {
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 1.05 !important;
        color: #1F384C !important;
    }
}

@media screen and (max-width: 900px) {
    .liveai-head-text-main-item {
        margin: 0px auto 50px !important;
    }

    .liveai-head-text-item {
        text-align: center !important;
        margin: 20px auto !important;
    }

    .liveai-head-normal-text-color {
        font-size: 25px !important;
    }

    .liveai-head-sub-normal-text-color {
        font-size: 20px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .liveai-head-normal-text-color {
        font-size: 26px !important;
    }

    .liveai-head-sub-normal-text-color {
        font-size: 16px !important;
    }

    .liveai-head-text-item {
        text-align: center !important;
        margin: 10px auto !important;
    }

    .liveai-head-text-comparison-item {
        text-align: center !important;
        margin: 10px auto !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .liveai-head-normal-text-color {
        font-size: 25px !important;
    }

    .liveai-head-sub-normal-text-color {
        font-size: 18px !important;
    }

    .liveai-head-text-item {
        text-align: center !important;
        margin: 10px auto !important;
    }

    .liveai-head-text-comparison-item {
        text-align: center !important;
        margin: 10px auto !important;
    }
}