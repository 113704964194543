.CBFC-mainOuterContainer {
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.CBW-mainOuterContainer {
  justify-content: center;
  margin-top: 15px;
}

.CBFC-IndexText {
  font-size: 80px;
}
.cbf-index-item {
  color: rgba(106, 9, 125, 0.6);
  font-size: 80px;
  font-weight: 600;
}
.CBFC-logoIndexContainer {
  justify-content: space-between;
}

.CBFC-IndexText {
  color: rgba(106, 9, 125, 0.5);
  font-size: 90px;
  font-weight: 600;
  vertical-align: top;
  /* For Adjusting word position */
  position: relative;
  bottom: 38px;
  left: 5px;
}

.CBFC-titleText {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  width: 95%;
  margin-bottom: 10px;
  min-height: 60px;
}

.CBFC-DescriptionText {
  color: #1f384c;
  font-size: 15px;
}

.CBW-cardMainOuterContainer {
  margin-top: 30px;
}

.CBW-card-container {
  gap: 40px;
  justify-content: center;
}
.CBW-cardContainer-item {
  max-width: 381px;
  width: 381px;
}

@media only screen and (min-width: 1900px) {
  .CBFC-titleText {
    font-size: 22px;
  }
  .CBFC-DescriptionText {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .CBFC-titleText {
    font-size: 19px;
  }
  .CBFC-DescriptionText {
    font-size: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .CBFC-titleText {
    font-size: 19px;
  }
  .CBFC-DescriptionText {
    font-size: 15px;
  }
  .CBW-card-container {
    gap: 30px;
  }
  .CBW-cardContainer-item {
    max-width: 50%;
    width: 45%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CBFC-titleText {
    font-size: 19px;
  }
  .CBFC-DescriptionText {
    font-size: 15px;
  }
  .CBW-card-container {
    gap: 30px;
  }
  .CBW-cardContainer-item {
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 650px) and (max-width: 1200px) {
  /* .CBW-cardContainer-item {
    max-width: 300px;
    width: 300px;
  } */
}
@media only screen and (max-width: 900px) {
  .CBW-card-container {
    /* justify-content: space-around; */
    /* row-gap: 50px; */
  }
}
