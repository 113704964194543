/* .common-format-main-item-section {
    background: linear-gradient(to right bottom, #f6f3ff, #cd9cf2) !important;
} */

.common-format-main-section {
    padding: 50px 25px !important;
    /* background: linear-gradient(to right bottom, #f3fddc, #F7E9FF) !important; */
    /* background: linear-gradient(to right bottom, #f7e9ff, #cd9cf2) !important; */
    border-radius: 20px !important;
}

.common-format-heading-text-style {
    font-size: 36px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    line-height: 1.25 !important;
}

.common-format-heading2-text-style {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #454545 !important;
    line-height: 1.25 !important;
}

.common-format-classification-text-style {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.common-format-sub-heading-text-style {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.common-format-button-text-style {
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

.common-format-image-style {
    width: 100% !important;
    max-width: 550px !important;
}

.common-format-left-image-style {
    text-align: start !important;
}

.common-format-right-image-style {
    text-align: end !important;
}

@media screen and (max-width: 1650px) {
    .common-format-heading-text-style {
        font-size: 32px !important;
    }
    .common-format-heading2-text-style {
        font-size: 22px !important;
    }
    .common-format-image-style {
        max-width: 500px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .common-format-main-section {
        padding: 30px !important;
    }
    .common-format-heading-text-style {
        font-size: 25px !important;
    }
    .common-format-heading2-text-style {
        font-size: 17px !important;
    }
    .common-format-classification-text-style {
        font-size: 16px !important;
    }
    .common-format-sub-heading-text-style {
        font-size: 14px !important;
    }
    .common-format-image-style {
        max-width: 450px !important;
    }
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
    .common-format-main-section {
        padding: 30px !important;
    }
    .common-format-heading-text-style {
        font-size: 25px !important;
    }
    .common-format-heading2-text-style {
        font-size: 17px !important;
    }
    .common-format-classification-text-style {
        font-size: 16px !important;
    }
    .common-format-sub-heading-text-style {
        font-size: 14px !important;
    }
    .common-format-left-image-style {
        text-align: center !important;
    } 
    .common-format-right-image-style {
        text-align: center !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .common-format-main-section {
        padding: 30px !important;
    }
    .common-format-heading-text-style {
        font-size: 23px !important;
    }
    .common-format-heading2-text-style {
        font-size: 16px !important;
    }
    .common-format-classification-text-style {
        font-size: 15px !important;
    }
    .common-format-sub-heading-text-style {
        font-size: 14px !important;
    }
    .common-format-left-image-style {
        text-align: center !important;
    }
    .common-format-right-image-style {
        text-align: center !important;
    }
}