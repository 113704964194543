.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    align-items: center;
}

.wrapper {
    position: absolute;
    display: flex;
    align-items: center;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
    align-items: center;
}

.scroll-image-spacing {
    margin: 0px 40px !important;
}

.scroll-image {
    /* filter: grayscale(100%); */
}

/* .slider::before,
.slider::after {
    background: linear-gradient(to right, #fdecff 0%, rgba(255, 255, 255, 0) 100%);
    content: "" !important;
    height: 100px !important;
    position: absolute !important;
    width: 230px !important;
    z-index: 2 !important;
} */

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

.cw-trusted-text-main-item {
    margin: 40px auto 0px !important;
}

.cw-trusted-normal-text-color {
    font-size: 27px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    line-height: 30px !important;
}

.trustedIconStyle {
    max-height: 50px !important;
}

@media screen and (max-width: 900px) {
    .cw-trusted-normal-text-color {
        font-size: 26px !important;
    }

    .trustedIconStyle {
        max-height: 40px !important;
    }
}

@media screen and (max-width: 600px) {
    .cw-trusted-normal-text-color {
        font-size: 23px !important;
    }

    .scroll-image-spacing {
        margin: 0px 20px !important;
    }
}