.common-grid-format-style {
    padding: 30px 20px !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
    max-width: 350px !important;
    border-radius: 10px !important;
}

.common-grid-format-style-without-border {
    padding: 30px 20px !important;
    max-width: 350px !important;
    border-radius: 10px !important;
}

.common-grid-heading-text-style {
    font-size: 17px !important;
    font-weight: 700 !important;
}

.flagNoBorder-common-grid-heading-text-style {
    font-size: 19px !important;
    font-weight: 700 !important;
}

.common-grid-subheading-text-style {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #7A7A7A;
}

.flagNoBorder-common-grid-subheading-text-style {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #7A7A7A;
}