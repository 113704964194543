.csf-main {
    background: url("../../assests/waves.png");
    background-repeat: no-repeat !important;
    background-position: center top !important;
}

.csf-main-item {
    margin: 130px auto 0px !important;
}

.csf-main-container {
    justify-content: space-between;
}


.csf-head-text {
    color: #6A097D !important;
    /* font-size: 60px !important; */
    font-size: 40px !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
}

.csf-head-text-item {
    margin: 15px 0px !important;
}

.csf-head-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
}

.csf-head-meta-provider {
    text-align: end !important;
}

.csf-head-sub-text {
    /* color: black !important; */
    color: #1F384C !important;
    font-size: 22px !important;
    font-weight: 500 !important;
}

.csf-point-text-item {
    margin: 15px 0px 30px !important;

}

.csf-point-text {
    /* color: black !important; */
    color: #1F384C !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    margin-bottom: 30px !important;
}

.csf-point-text li {
    background-image: url('../../assests//rightTickMark.svg');
    /* Replace 'your-image.png' with your image URL */
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 20px;
    list-style: none;
    padding-left: 0;
}

.csf-list-item {
    align-items: flex-start !important;
    padding: 0px 0px !important;
}

.csf-list-icon {
    height: 20px !important;
    width: auto !important;
    min-width: 30px !important;
    margin-top: 7px !important;
}

.csf-list-text-title {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
}

.csf-list-text {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.csf-intrested-dropdown {
    border-radius: 3px !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
}

.csf-intrested-dropdown .MuiAutocomplete-input {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.csf-intrested-dropdown .MuiSelect-select {
    padding: 8.5px 14px !important;
}

.csf-intrested-dropdown .MuiAutocomplete-inputRoot {
    padding: 1px 9px !important;
}

.csf-intrested-dropdown fieldset {
    border: none !important;
}

.csf-point-text li {
    color: black !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    margin: 5px 0px !important;
}

.csf-point-text li span {
    display: inline-block;
    margin-left: 10px;
    /* Adjust the margin between the image and text as needed */
}

.csf-form-item {
    margin: 0 auto !important;
    padding: 20px 40px !important;
    border-radius: 10px;
    background-color: #F9F7F7 !important;
}

.csf-form-item {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.csf-form-item .MuiBackdrop-root {
    position: absolute !important;
}

.csf-form-head-text {
    margin: 15px 0px 15px !important;
}

.csf-form-head-textfield {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.csf-form-label-item {
    margin: 0px 0px 5px !important;
}

.csf-global-label {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.csf-radio-btn {
    font-size: 14px !important;
}

.csf-request-demo-btn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    padding: 10px 48px !important;
}

.csf-comman-global-item {
    margin: 10px 0px !important;
    display: flex !important;
    justify-content: center !important;
}

.csf-global-small-text {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.csf-global-label-item {
    margin: 5px 0px !important;
}

.csf-textarea {
    outline: none;
    border: none !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    /* border: 1px solid #D9D9D9; */
}

.csf-textarea:focus {
    border: 2px solid rgb(25, 118, 210);
}

.csf-comman-global-sub-item {
    margin: 10px 0px !important;
}


.mainGrid {
    z-index: 1;
    min-width: 350px !important;
}


.upperGrid {
    background-color: #FFF;
    padding: 0px 30px 25px;
    border-radius: 12px;
    min-width: 250px !important;
    max-width: 350px !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.meterWrapper {
    padding: 30px 0px 20px
}

.heading {
    font-size: 16px;
    font-weight: 600;
    color: #1F384C;
    margin-bottom: 10px !important;
    text-align: center;
}

.signUpBtnWrapper {
    margin: 15px 0px 5px !important;
    font-size: 15px !important;
    border: 2px solid #6A097D !important;
    color: #6A097D !important;
    padding: 10px 50px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
}

.closeButtonGrid {
    position: relative !important;
}

.closeButtonGridItem {
    position: absolute !important;
    position: absolute !important;
    right: -10px !important;
    top: 10px !important;
}

.closeButtonStyle {
    border-radius: 50% !important;
    border-radius: 50% !important;
    Padding: 10px !important;
    min-width: 0px !important;
}

.closeIcon {
    color: black !important;
    color: black !important;
    cursor: pointer !important;
}

.closeButton {
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    min-width: 0 !important;
    padding: 0 !important;
}

.csf-form-textfields fieldset {
    border: none !important;
    outline: none !important;
}

.csf-form-textfields {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px !important;
}

.csf-form-textfields .MuiOutlinedInput-input {
    font-size: 14px !important;
    font-weight: 500 !important;
}

@media screen and (min-width : 2100px) {
    .csf-head-text {
        font-size: 46px !important;
    }

    .csf-head-sub-text {
        font-size: 24px !important;
    }

    .csf-point-text {
        font-size: 24px !important;
    }

    .csf-list-text-title {
        font-size: 22px !important;
    }

    .csf-list-text {
        font-size: 22px !important
    }

    .csf-form-head-textfield {
        font-size: 30px !important;
    }

    .csf-global-label {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 1900px) and (min-width : 1200px) {
    .csf-head-text {
        font-size: 34px !important;
    }

    .csf-head-sub-text {
        font-size: 17px !important;
    }

    .csf-point-text {
        font-size: 17px !important;
    }

    .csf-list-text-title {
        font-size: 15px !important;
    }

    .csf-list-text {
        font-size: 15px !important
    }
}

@media screen and (max-width: 1200px) and (min-width : 900px) {
    .csf-head-text {
        font-size: 40px !important;
    }

    .csf-head-sub-text {
        font-size: 18px !important;
    }

    .csf-point-text {
        font-size: 18px !important;
    }

    .csf-list-text-title {
        font-size: 16px !important;
    }

    .csf-list-text {
        font-size: 16px !important
    }
}

@media screen and (max-width: 900px) and (min-width : 600px) {
    .csf-head-text {
        font-size: 34px !important;
    }

    .csf-head-sub-text {
        font-size: 17px !important;
    }

    .csf-point-text {
        font-size: 17px !important;
    }

    .csf-list-text-title {
        font-size: 15px !important;
    }

    .csf-list-text {
        font-size: 15px !important
    }
}

@media screen and (max-width: 600px) and (min-width : 300px) {
    .csf-head-text {
        font-size: 28px !important;
    }

    .csf-head-sub-text {
        font-size: 17px !important;
    }

    .csf-point-text {
        font-size: 17px !important;
    }

    .csf-list-text-title {
        font-size: 15px !important;
    }

    .csf-list-text {
        font-size: 15px !important
    }
}

@media screen and (max-width: 599px) and (min-width : 325px) {
    .csf-main-container {
        padding-inline: 10px !important;
    }


    .csf-main-item {
        margin: 80px auto 0px !important;
    }


    .csf-head-text-item {
        margin-top: 0px !important;
    }


    .csf-head-container {
        flex-direction: column-reverse !important;
        align-items: start !important;
    }

    .csf-form-item {
        padding: 20px !important;
    }


    .csf-form-head-text {
        margin: 5px 0px 5px !important;
    }


    .csf-form-head-textfield {
        font-size: 20px !important;
    }


    .csf-comman-global-sub-item {
        margin: 5px 0px 0px !important;
    }


    .upperGrid {
        padding: 0px 20px 25px;
        max-width: 300px !important;
    }
}

@media screen and (max-width: 600px) and (min-width : 325px) {
    .csf-global-textfield-mb-item {
        margin: 5px 0px !important;
    }
}


.cube-container {
    perspective: 1000px;
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.cube {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    animation: spin 8s infinite linear;
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}




.cube {
    width: 200px;
    height: 200px;
    /* position: relative; */
    /* transform-style: preserve-3d; */
    /* animation: spin 8s infinite linear;
     */
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
}

.front {
    background-color: red;
    transform: translateZ(100px);
}

.back {
    background-color: blue;
    transform: rotateX(180deg) translateZ(100px);
}

.top {
    background-color: black;
    transform: rotateX(-90deg) translateZ(100px);
}

.bottom {
    background-color: green;
    transform: rotateX(90deg) translateZ(100px);
}

.left {
    background-color: yellow;
    transform: rotateY(-90deg) translateZ(100px);
}

.right {
    background-color: purple;
    transform: rotateY(90deg) translateZ(100px);
}

@keyframes spin {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(360deg);
    }
}