.cw-expertise-text-main-item {
    margin: 40px auto 0px !important;
}

.cw-expertise-normal-text-color {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #333333 !important;
    line-height: 30px !important;
}

.expertiseIconStyle {
    max-height: 50px !important;
}

@media screen and (max-width: 900px) {
    .cw-expertise-normal-text-color {
        font-size: 26px !important;
    }
    .expertiseIconStyle {
        max-height: 5vw !important;
    }
}

@media screen and (max-width: 600px) {
    .cw-expertise-normal-text-color {
        font-size: 23px !important;
    }
}