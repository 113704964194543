.pr-ai-white-overlap-item {
    background-color: #fff !important;
    border-radius: 20px !important;
    z-index: 1 !important;
    margin: 0 auto !important;
    margin-bottom: -80px !important;
    padding: 30px 30px 30px 50px !important;
}

.pr-ai-question-item {
    text-align: center !important;
    margin: 20px 0px !important;

}

.pr-ai-faq-main-head {
    color: #000000 !important;
    font-size: 45px !important;
    font-weight: 600 !important;
}

.pr-ai-faq-sub-head {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.pr-ai-faq-accordion {
    box-shadow: none !important;
    position: inherit !important;
    border: 2px solid #fff !important;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2) !important;
    margin-top: 25px !important;
    /* border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; */
    border-radius: 10px !important;
}

.pr-ai-expand-accordion-summary {
    cursor: auto !important;
    min-height: 60px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: #fff !important;
    padding: 0px 15px !important;
}

.pr-ai-expand-accordion-summary .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg) !important;
}

.pr-ai-expand-accordion-summary .Mui-expanded {
    transform: rotate(360deg) !important;
    margin: 0px 0px !important;
}

.pr-overlap-item {
    position: relative;
}

.pr-ai-footer-main-item {
    position: absolute;
    width: 100%;
    top: -80px;
}

.pr-ai-chatbot-item {
    position: absolute;
    width: 100%;
    top: -100px;
}

.pr-ai-footer-main-container {
    align-items: center;
    position: relative;
    width: 100% !important;
    /* margin-top: 80px !important; */
}

.pr-overlap-head-item h2 {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.pr-overlap-sub-item p {
    font-size: 16px;
    font-weight: 600;
    color: #1F384C;
}

.pr-contact-icons {
    width: 20px;
    height: auto;
}

.pr-ai-avtar {
    /* width: 100px !important; */
    height: 100px !important;
    aspect-ratio: 1.02 !important;
    border-radius: 50%;
}

.pr-ai-global-head-text {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

@media screen and (max-width : 600px) and (min-width : 0px) {
    .pr-ai-global-head-text {
        color: #1F384C !important;
        font-size: 23px !important;
        font-weight: 500 !important;
    }

    .pr-ai-faq-main-head {
        color: #6A097D !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .pr-ai-faq-sub-head {
        color: #1F384C !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .pr-overlap-head-item h2 {
        font-size: 22px !important;
        font-weight: 600 !important;
        color: #6A097D !important;
    }

    .pr-overlap-sub-item p {
        font-size: 14px;
        font-weight: 600;
        color: #1F384C;
    }

    .pr-overlap-head-item {
        text-align: start !important;
    }

    .pr-overlap-sub-item {
        text-align: start !important;
    }

    .pr-ai-white-overlap-item {
        margin-bottom: -80px !important;
        padding: 30px 15px 30px 15px !important;
    }

    .pr-ai-contact-label-item span {
        font-size: 14px;
        font-weight: 600;
    }

    .pr-contact-label-main-item {
        width: 50%;
        max-width: 50%;
        margin: 2px 0px !important;
    }

    .pr-contact-icons {
        width: 15px;
        height: auto;
    }

    .pr-ai-footer-main-container {
        margin-top: -60px !important;
    }
}

@media screen and (min-width : 0px) and (max-width : 405px) {
    .pr-ai-contact-label-item span {
        font-size: 15px;
        font-weight: 500;
    }

    .pr-contact-icons {
        width: 20px;
        height: auto;
    }

    .pr-contact-label-main-item {
        width: 100%;
        max-width: 100%;
        margin: 5px 0px !important;
    }

    .lp-ft-overlap-text-main-item {
        margin-bottom: 10px !important;
    }

    .pr-ai-chatbot-item {
        position: absolute;
        width: 100% !important;
        top: -150px !important;
    }

}

@media screen and (min-width : 600px) and (max-width : 900px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }
}

@media screen and (min-width : 901px) and (max-width : 966px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }
}

@media screen and (min-width : 1200px) and (max-width : 1366px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }

}

@media screen and (min-width : 600px) and (max-width : 1440px) {
    .pr-ai-white-overlap-item {
        padding: 30px 25px 30px 26px !important;
    }

    .pr-ai-avtar {
        width: 100px !important;
        height: 100px !important;
    }

    .pr-ai-footer-main-container {
        margin-top: -60px !important;
    }
}

@media screen and (min-width : 600px) and (max-width : 900px) {
    .pr-ai-footer-main-container {
        margin-top: -80px !important;
    }
}