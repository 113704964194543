.gitex-timer-component {
    margin: 140px auto 0px !important;
}

.gitex-scrolling-component {
    margin: 340px auto 100px !important;
}

.gitex-banner-component {
    margin: 100px auto 0px !important;
}

.cag-head-container {
    position: relative;
}

.cag-banner-img-item {
    margin: 0 auto !important;
}

.cag-banner-img {
    width: 100%;
    height: 300px;
}