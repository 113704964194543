.arp-diff-table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 15px;
    font-weight: 500;
    color: black;
    padding: 5px;
}

.arp-diff-table,
th {
    font-size: 15px;
    font-weight: 600;
    color: black;
}