/* plan faq */

.pp-plan-faq-main-head-item {
    margin: 5px auto !important;
    text-align: center !important;
}

.pp-plan-faq-head {
    color: #6A097D;
    font-size: 40px !important;
    font-weight: 600 !important;
}

.pp-plan-faq-subtitle-item {
    text-align: center;
    margin: 5px auto !important;
}

.pp-plan-faq-subtitle {
    color: #7A7A7A;
    font-weight: 500;
}

.pp-plan-faq-item {
    margin: 100px auto !important;
}

.pp-plan-faq-head-item {
    margin-bottom: 20px !important;
}


.pp-faq-accordion {
    box-shadow: none !important;
    position: inherit !important;
    border: 2px solid #FBF4FF !important;
    margin-top: 15px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.collapse-accordion-summary-border {
    border-bottom: 1px solid #eaecf0 !important;
}

.pp-faq-details-section {
    padding: 20px 15px 35px !important;
    border-bottom: 1px solid #eaecf0 !important;
}

.pp-faq-expand-accordion-summary {
    cursor: auto !important;
    min-height: 60px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: #FBF4FF !important;
    padding: 0px 15px !important;
}

.pp-faq-expand-accordion-summary .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(180deg) !important;
}

.pp-faq-expand-accordion-summary .Mui-expanded {
    transform: rotate(360deg) !important;
    margin: 0px 0px !important;
}

.expand-icon {
    cursor: pointer !important;
    color: #475467 !important;
    font-size: 35px !important;
}

.pp-faq-ques-head {
    color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    cursor: auto;
}

.pp-faq-ans-text {
    color: black !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

li.pp-faq-ans-text {
    padding: 5px 0;
}

li.pp-faq-ans-text:first-of-type {
    padding-top: 20px;
}

.pp-faq-ans-list {
    list-style-type: none;
}

@media only screen and (min-width: 320px) and (max-width: 499px) {
    .pp-plan-faq-head {
        font-size: 18px !important;
    }

    .pp-faq-ques-head {
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    .pp-faq-ans-text {
        font-size: 13px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
    .pp-plan-faq-item {
        margin: 50px auto !important;
    }

    .pp-plan-faq-head-item {
        margin-bottom: 0px !important;
    }

}

/* plan faq */