.nav-drawer-main-container {
    height: 100%;
}

.nav-drawer-main-item {
    height: 100%;
}

.nav-drawer-main-wrapper {
    height: 100%;
}

.nav-drawer-menu-main-item {
    height: calc(100% - 60px) !important;
    overflow: auto !important;
    margin: 20px auto !important;
}


.nav-menu-accordian {
    box-shadow: none !important;
    border-radius: 0px !important;
}

.nav-accordian-head-text {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: black !important;
}

.nav-accordian-menu-text {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.nav-menu-accordian-summery {
    padding: 0px !important;
}

.nav-menu-accordian-summery .MuiAccordionSummary-content {
    margin: 5px auto !important;
}

.nav-drawer-item .MuiAccordion-root::before {
    background-color: transparent !important;
}

.nav-menu-accordian .MuiButtonBase-root {
    height: 40px !important;
    max-height: 40px !important;
    min-height: 40px !important;
}

.nav-accordian-link-item {
    margin: 5px 0px !important;
}

.nav-menu-accordian-details {
    padding: 5px !important;
}

.nav-login-btn {
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 1px solid #6A097D !important;
    color: #6A097D !important;
    text-transform: none !important;
}