.cbe-main-container {
    border-bottom: 5px solid;
    border-image: linear-gradient(to right, #3b78ce 33%, #e8e021 66%);
    border-image-slice: 1;
}

.cbe-main-container-item {
    margin: 90px auto 110px auto !important;
    /* margin-top: 40px !important;
    margin-bottom: 80px !important; */
}

.cbe-chat-img-container {
    justify-content: center;
    /* max-width: 520px; */
    /* width: 100%; */
    margin: auto;
}

.cbe-chatframe-img {
    display: flex;
    margin: auto;
}

.cbe-content-container {
    align-items: start;
    justify-content: space-between;
}

.cbe-text-content-container {
    text-align: start !important;
}

.cbe-main-heading {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #4c4a4d;
}

.cbe-text-item-typo {
    font-size: 17px !important;
    font-weight: 500 !important;
    color: #1f384c !important;
    letter-spacing: normal !important;
}

.cbe-demo-button {
    font-size: 22px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #6a097d !important;
    border: none !important;
    text-transform: none !important;
    padding: 15px 0px !important;
    display: flex !important;
    align-items: center !important;
}

.cbe-demo-button:hover {
    background-color: transparent !important;
}

.cbe-demo-button span {
    border: none !important;
}

.cbe-text-item {
    margin-top: 25px !important;
}

.cbe-demo-button-item {
    margin-top: 35px !important;
}

.cbe-left-seciton-main-item {
    margin: 70px 0px 0px !important;
    padding: 0px 37px !important;
}

.cbe-chat-img-container iframe .MuiBox-root {
    padding: 0 !important;
}

.cbe-i-frame-item {
    margin-top: 0px;
}
.cbe-main-heading-color {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #6c0d7f !important;
}
.cbe-hifi-icon {
    width: 30px;
    height: auto;
    object-fit: fill;
}
.cbe-left-section-subheading-item {
    margin: 15px 0px 0px !important;
}
.cbe-left-section-subheading {
    font-size: 23px !important;
    font-weight: 500 !important;
    color: #1f384c !important;
}
.cbe-left-section-subheading-color {
    font-size: 23px !important;
    font-weight: 500 !important;
    color: #6c0d7f !important;
}

/* sx={{ fontSize: "40px", fontWeight: "500", color: "#6C0D7F" }} */
/* @media only screen and (max-width: 1200px) {
    .cbe-i-frame-item {
        margin-top: 50px !important;
    }

    .cbe-left-seciton-main-item {
        padding: 0px !important;
    }
} */

@media only screen and (min-width: 1900px) {
    .cbe-main-heading,
    .cbe-main-heading-color {
        font-size: 40px !important;
    }
    .cbe-left-section-subheading-color,
    .cbe-left-section-subheading {
        font-size: 21px !important;
    }
    .cbe-text-item-typo {
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1900px) {
    .cbe-main-heading,
    .cbe-main-heading-color {
        font-size: 36px !important;
    }
    .cbe-left-section-subheading-color,
    .cbe-left-section-subheading {
        font-size: 22px !important;
    }
    .cbe-text-item-typo {
        font-size: 17px !important;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .cbe-main-heading,
    .cbe-main-heading-color {
        font-size: 30px !important;
    }
    .cbe-hifi-icon {
        width: 35px;
    }
    .cbe-left-section-subheading,
    .cbe-left-section-subheading-color {
        font-size: 20px !important;
    }
    .cbe-text-item-typo {
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .cbe-main-heading,
    .cbe-main-heading-color {
        font-size: 30px !important;
    }
    .cbe-hifi-icon {
        width: 30px;
    }
    .cbe-left-section-subheading,
    .cbe-left-section-subheading-color {
        font-size: 19px !important;
    }
    .cbe-text-item-typo {
        font-size: 17px !important;
    }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cbe-main-container {
        border: none;
    }
    .cbe-main-heading,
    .cbe-main-heading-color {
        font-size: 25px !important;
    }
    .cbe-hifi-icon {
        width: 30px;
    }
    .cbe-left-section-subheading,
    .cbe-left-section-subheading-color {
        font-size: 18px !important;
    }
    .cbe-text-item-typo {
        font-size: 16px !important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .cbe-main-container-item {
        margin: 35px auto 45px auto !important;
    }
    .cbe-left-seciton-main-item {
        margin: 0px auto !important;
        padding: 0px !important;
    }
    .cbe-left-section-heading-container {
        justify-content: center;
    }
    /* .cbe-main-heading {
        font-size: 30px !important;
    } */
    /* .cbe-main-heading-color {
        font-size: 30px !important;
    } */
    /* .cbe-hifi-icon {
        width: 22px;
    } */
    .cbe-left-section-subheading-item {
        text-align: center;
        margin: 5px 0px 0px !important;
    }
    /* .cbe-left-section-subheading {
        font-size: 20px !important;
    } */
    /* .cbe-left-section-subheading-color {
        font-size: 20px !important;
    } */
    /* .cbe-text-item-typo {
        font-size: 15px !important;
    } */
    .cbe-demo-button-item {
        margin-top: 20px !important;
    }
    .cbe-i-frame-item {
        margin-top: 40px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .cbe-main-container-item {
        margin: 35px auto 45px auto !important;
    }
    .cbe-left-seciton-main-item {
        margin: 0px auto !important;
        padding: 0px !important;
    }
    .cbe-left-section-heading-container {
        justify-content: center;
    }
    .cbe-hifi-icon {
        width: 22px;
    }
    .cbe-left-section-subheading-item {
        text-align: center;
        margin: 5px 0px 0px !important;
    }
    .cbe-demo-button-item {
        margin-top: 20px !important;
    }
    .cbe-i-frame-item {
        margin-top: 20px !important;
    }
    .cbe-try-it-now-container {
        justify-content: center;
    }
}
