.arp-head-main-item {
  /* min-height: 200px !important; */
  /* background-color: #FDF9FF !important; */
  /* background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 6%, rgba(255, 255, 255, 1) 21%, rgba(254, 252, 255, 1) 26%, rgba(238, 201, 255, 1) 100%); */
  padding: 120px 0px 0px !important;
}

.arp-main-head-item {
  text-align: center !important;
  margin: 0 auto !important;
}

.arp-main-head {
  color: #6A097D !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.arp-subtitle-text-item {
  margin: 10px auto !important;
  text-align: center !important;
}

.arp-subtitle-text {
  color: #7A7A7A !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.arp-search-item {
  margin: 60px auto 0px !important;
}

.arp-searchbar {
  border-radius: 10px !important;
  background: #FFF !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  color: black !important;
}

.arp-chatbot-blog-head {
  font-size: 37px !important;
  font-weight: 600 !important;
  color: black !important;
}

.blog-bg-image-item {
  margin: 30px auto 0px !important;
  text-align: start !important;
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .blog-bg-image {
    object-fit: contain !important;
    height: auto !important;
    border-radius: 5px !important;
  }

}


/* .blog-bg-image-item {
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image: linear-gradient(black, transparent);
} */