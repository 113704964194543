.healthcare-new-hero-section {
    margin: 0px auto !important;
}

.common-format-new-head-section-main-item {
    margin: 0px auto 50px !important;
}

.common-format-new-card-section {
    margin: 40px auto 0px !important;
}

.common-format-new-footer-section {
    margin: 120px auto !important;
}