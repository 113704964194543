.banner-background-style {
    /* background: linear-gradient(to right, rgba(8, 40, 65, 1) 25%, rgba(164, 29, 33, 1), rgba(179, 58, 50, 1)); */
    background: url('./../../assests/LandingPage/GitexNavbarBanner.png');
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100% !important;
    padding: 12px 70px !important;
    position: fixed !important;
    top: 0 !important;
    z-index: 100 !important;
    position: relative;
}

.join-us-text-style {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: rgba(255, 255, 255, 1) !important;
}

.book-slot-button-style {
    background-color: white !important;
    color: rgba(172, 0, 25, 1) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    box-shadow: 0px 0px 2px rgba(106, 9, 125, 1) !important;
    border-radius: 30px !important;
    padding: 6px 20px !important;
    text-transform: none !important;
}

.number-heading-style {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: white !important;
    display: inline !important;
}

.number-value-style {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: white !important;
    display: block !important;
}

.hall-number-style {
    border-right: 1px solid white !important;
    padding-right: 17px !important;
}

.close-banner-style {
    position: absolute !important;
    right: 15px !important;
}

.book-meet-btn {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #AC0019 !important;
    background-color: white !important;
    text-transform: none !important;
    border-radius: 5px !important;
    padding: 8px 10px !important;
}

.b-chronox-ai-logo {
    width: 150px;
    height: auto;
}

.crnx-middle-item {
    padding-left: 60px !important;
}

@media only screen and (min-width: 1440px) and (max-width: 1535px) {
    .join-us-text-style {
        font-size: 20px !important;
    }

    .number-heading-style {
        font-size: 15px !important;
    }


}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .join-us-text-style {
        font-size: 20px !important;
    }

    .book-meet-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        padding: 8px 10px !important;
    }

    .number-heading-style {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .join-us-text-style {
        font-size: 18px !important;
    }

    .book-meet-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        padding: 8px 10px !important;
    }

    .number-heading-style {
        font-size: 15px !important;
    }

    /* .banner-background-style {
        padding: 12px 35px !important;
        top: 0 !important;
    } */

    .close-banner-style {
        position: absolute !important;
        right: 8px !important;
    }

    .banner-background-style {
        /* background: linear-gradient(to right, rgba(8, 40, 65, 1) 25%, rgba(164, 29, 33, 1), rgba(179, 58, 50, 1)); */
        background: url('./../../assests/LandingPage/GitexNavbarBanner.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100% !important;
        padding: 12px 30px !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 100 !important;
        position: relative;
    }
}

@media screen and (max-width: 900px) {


    .close-banner-style {
        position: absolute !important;
        right: 8px !important;
        top: 8px !important;
    }

    .join-us-text-style {
        font-size: 18px !important;
    }

    .number-heading-style {
        display: block !important;
        font-size: 14px !important;
    }

    /* 
    .banner-background-style {
        background: linear-gradient(to right, rgba(8, 40, 65, 1) 25%, rgba(164, 29, 33, 1), rgba(179, 58, 50, 1)) !important;
    } */
}

@media only screen and (min-width: 0px) and (max-width: 899px) {

    .book-meet-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        padding: 8px 10px !important;
    }

    .number-heading-style {
        font-size: 15px !important;
    }

    /* .banner-background-style {
        padding: 12px 35px !important;
        top: 0 !important;
    } */

    .close-banner-style {
        position: absolute !important;
        right: 8px !important;
    }

    .banner-background-style {
        /* background: linear-gradient(to right, rgba(8, 40, 65, 1) 25%, rgba(164, 29, 33, 1), rgba(179, 58, 50, 1)); */
        background: url('./../../assests/LandingPage/GitexMobileNavbarBanner.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100% !important;
        padding: 25px 30px !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 100 !important;
        position: relative;
    }

    .crnx-middle-item {
        padding: 0px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .join-us-text-style {
        font-size: 16px !important;
    }

    .book-meet-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        padding: 8px 10px !important;
    }

    .number-heading-style {
        font-size: 12px !important;
    }

    .close-banner-style {
        position: absolute !important;
        right: 8px !important;
    }

    .banner-background-style {
        background: url('./../../assests/LandingPage/GitexMobileNavbarBanner.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100% !important;
        padding: 25px 25px !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 100 !important;
        position: relative;
    }

    .crnx-middle-item {
        padding: 0px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 370px) {
    .b-chronox-ai-logo {
        width: 100px;
        height: auto;
    }

    .b-gitex-logo {
        width: 100px;
        height: auto;
    }
}