.inm-get-started-btn {
    background-color: rgba(106, 9, 125, 1) !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.im-main-head-item {
    margin: 200px auto 0px !important;
}

.im-main-head-item-without-banner {
    margin: 20px auto 0px !important;
}

.im-globaltab-head-item {
    margin: 50px 0px !important;
}

.im-comman-item {
    margin: 50px 0px !important;
}

.im-overlap-item {
    position: relative;
}


.im-simplified-item {
    margin: 0px auto 150px !important;
}

.im-heading-label {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: rgba(106, 9, 125, 1) !important;
}

@media screen and (max-width: 900px) and (min-width: 600px) {
    .im-tab-item {
        margin: 250px 0px !important;
    }
}

@media screen and (max-width: 600px) {
    .int-main-container {
        margin: 80px 0px 0px;
    }

    .int-main-container-without-banner {
        margin: 0px;
    }
}