.omcs-head-point-text {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: rgba(255, 255, 255, 1) !important;
    line-height: 55px !important;
}

.omcs-subhead-point-text {
    font-size: 17px !important;
    font-weight: 300 !important;
    color: white !important;
    line-height: 24px !important;
}

.omcs-head-point-text-item {
    margin: 0px 0px 30px !important;
}

.omcs-subhead-point-text-item {
    margin: 10px 0px 50px !important;
}

.omcs-main-item {
    background: linear-gradient(108deg, #4B81D2 0.94%, #79228A 57.4%);
    padding: 90px 0px !important;
}

.omini-image {
    max-width: 600px !important;
    width: 100% !important;
    object-fit: cover;
    height: auto;
}

.omcs-content-item {
    margin: 0 auto !important;
    max-width: 1500px !important;
}

.omcs-content-container {
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}

.omini-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    border-radius: 5px !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.omini-image-item {
    text-align: center;
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .omcs-head-point-text {
        font-size: 30px !important;
        line-height: normal !important;
    }


    .omcs-subhead-point-text {
        font-size: 16px !important;

    }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {

    .omini-image-item {
        text-align: center;
        margin: 50px 0px 0px !important;
    }

    .omcs-head-point-text {
        font-size: 30px !important;
        line-height: normal !important;
    }


    .omcs-subhead-point-text {
        font-size: 16px !important;

    }
}